import {Component, OnInit} from '@angular/core';
import {RandomService} from "../../../services/misc/random.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loaderText: string = '';

  showLoader: boolean = false

  constructor(
    public randomService: RandomService
  ) { }

  ngOnInit(): void {
    this.randomService.showLoader.subscribe({
      next: (value: boolean) => {
        this.showLoader = value;
      }
    })

    this.randomService.showLoaderText.subscribe({
      next: (value: string) => {
        this.loaderText = value;
      }
    })
  }

}
