import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, catchError, firstValueFrom, map, take} from 'rxjs';
import {AmplitudeService} from "../events/amplitude.service";
import {SelfServeService} from "./self-serve.service";
import {ToastService} from "../misc/toast.service";

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  _SHOWROOM_SHIPPING_ADDRESS = new BehaviorSubject<any>(undefined);

  constructor(
    public http: HttpClient,
    public selfServeService: SelfServeService,
    public amplitudeService: AmplitudeService,
    public toastService: ToastService
    ) {}

  get showroomShippingAddress(): any {
    return this._SHOWROOM_SHIPPING_ADDRESS.getValue();
  }
  set showroomShippingAddress(value: any) {
    this._SHOWROOM_SHIPPING_ADDRESS.next(value);
  }

  /**
   * [POST] API to fetch QR code of Showroom
   * @param requestBody
   */
  getPropertyQrCode(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/showroom/qr-code/generate`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    )
  }

  /**
   * [PUT] API to save showroom shipping address
   */
  saveShowroomShippingAddress(requestBody: any) {
    return this.http
      .put(`${environment.apiUrl}/showroom/shipping-address/update`, requestBody)
      .pipe(
        take(1),
        map((response: any) => response.data),
        catchError((err) => {
          throw err;
        })
      );
  }

  /**
   * [POST] API to update publish step
   */
  updatePublishStepApi(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/showroom/publish/step/update`, requestBody)
      .pipe(
        take(1),
        map((response: any) => response.data), catchError((err) => {
          throw err
        })
      );
  }

  async downloadFile(propertyShareInfo: any) {
    if(propertyShareInfo?.qrCodeUrl) {
      let link = document.createElement('a');
      link.href = await this.toDataURL(propertyShareInfo?.qrCodeUrl);
      link.download = `Minoan QR Code - ${propertyShareInfo.showroomName}`;
      document.body.appendChild(link);
      link.click();
      this.amplitudeService.trackEvent('showroom_share_download_qr_click', this.selfServeService.userPropertyAccountId)
      document.body.removeChild(link);
    } else {
	    this.toastService.showErrorToast('No QR code found');
    }
  }

  async toDataURL(url: string) {
    const blob = await fetch(url).then(res => res.blob());
    return URL.createObjectURL(blob);
  }

  async updatePublishStep(requestBody: any) {
    return firstValueFrom(this.updatePublishStepApi(requestBody))
  }
}
