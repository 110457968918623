<div class="getting_started_page">
  <div class="inner_wrapper">
    <div class="heading_sec">
        <h4>GETTING STARTED</h4>
        <button class="close_btn" (click)="gettingBack()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8L1.75 1.75M8 8L14.25 14.25M8 8L14.25 1.75M8 8L1.75 14.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
    </div>

    <div class="accordian_warpper">
        <p-accordion [activeIndex]="currentActiveIndex" expandIcon="down_icon" collapseIcon="up_icon" [multiple]="true" (onOpen)="handleAccordionOpen($event)" >
          <p-accordionTab  header="" [ngClass]="{'p-accordion-tab-complete': ftuxArr[0]}" >
            <ng-template pTemplate="header">
              <div class="content_sec">
                  <span class="circle_icon">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8.5" cy="8.5" r="8.5" fill="#E0E1E5"/>
                      <path d="M12.0075 6.05493L7.33252 10.7299L5.20752 8.60493" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                  Install the Shopping Assistant
              </div>
            </ng-template>
              Our Google Chrome Extension is the easiest way to add items to your Minoan Cart as you shop the web. <span class="italic">Only available on desktop.</span>
          </p-accordionTab>
          <p-accordionTab  header="" [ngClass]="{'p-accordion-tab-complete': ftuxArr[1]}">
            <ng-template pTemplate="header">
              <div class="content_sec">
                  <span class="circle_icon">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8.5" cy="8.5" r="8.5" fill="#E0E1E5"/>
                      <path d="M12.0075 6.05493L7.33252 10.7299L5.20752 8.60493" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                Browse discounts and shop for items
              </div>
            </ng-template>
            <p>Explore our brands & discounts, then shop online with any of our 150+ brand partners.</p>
              <div class="btn_sec">
                  <a class="icon_text_btn" [href]="gettingStartedService.getStepLink(1)" target="_blank" (click)="ftuxStepCtaClick($event,1)">
                      <i class="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z" stroke="#DC5A4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M7.9165 7.47107C7.9165 7.07333 7.9165 6.87446 7.99962 6.76344C8.07206 6.66669 8.18293 6.60616 8.30349 6.59755C8.44182 6.58767 8.60911 6.6952 8.94368 6.91028L12.8775 9.43917C13.1678 9.62581 13.313 9.71913 13.3631 9.83778C13.4069 9.94146 13.4069 10.0585 13.3631 10.1621C13.313 10.2808 13.1678 10.3741 12.8775 10.5607L8.94368 13.0896C8.60911 13.3047 8.44182 13.4123 8.30349 13.4024C8.18293 13.3938 8.07206 13.3332 7.99962 13.2365C7.9165 13.1255 7.9165 12.9266 7.9165 12.5288V7.47107Z" stroke="#DC5A4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </i>
                      Show me how
                  </a>
              </div>
          </p-accordionTab>
          <p-accordionTab header="" [ngClass]="{'p-accordion-tab-complete': ftuxArr[2]}">
            <ng-template pTemplate="header">
              <div class="content_sec">
                  <span class="circle_icon">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8.5" cy="8.5" r="8.5" fill="#E0E1E5"/>
                      <path d="M12.0075 6.05493L7.33252 10.7299L5.20752 8.60493" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                Add items to your Minoan Cart
              </div>
            </ng-template>
            To access Minoan's insider pricing, add items directly to your Minoan Cart as you shop.
            <div class="btn_sec">
              <a class="icon_text_btn" [href]="gettingStartedService.getStepLink(2)" target="_blank" (click)="ftuxStepCtaClick($event,2)">
                <i class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z" stroke="#DC5A4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.9165 7.47107C7.9165 7.07333 7.9165 6.87446 7.99962 6.76344C8.07206 6.66669 8.18293 6.60616 8.30349 6.59755C8.44182 6.58767 8.60911 6.6952 8.94368 6.91028L12.8775 9.43917C13.1678 9.62581 13.313 9.71913 13.3631 9.83778C13.4069 9.94146 13.4069 10.0585 13.3631 10.1621C13.313 10.2808 13.1678 10.3741 12.8775 10.5607L8.94368 13.0896C8.60911 13.3047 8.44182 13.4123 8.30349 13.4024C8.18293 13.3938 8.07206 13.3332 7.99962 13.2365C7.9165 13.1255 7.9165 12.9266 7.9165 12.5288V7.47107Z" stroke="#DC5A4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                Show me how
              </a>
            </div>
          </p-accordionTab>
          <p-accordionTab header="" [ngClass]="{'p-accordion-tab-complete': ftuxArr[3]}">
            <ng-template pTemplate="header">
              <div class="content_sec">
                  <span class="circle_icon">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8.5" cy="8.5" r="8.5" fill="#E0E1E5"/>
                      <path d="M12.0075 6.05493L7.33252 10.7299L5.20752 8.60493" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                Request a quote
              </div>
            </ng-template>
            Your quote will be ready within a couple of hours and will include discounted Minoan pricing & delivery estimates.
            <div class="btn_sec">
              <a class="icon_text_btn" [href]="gettingStartedService.getStepLink(3)" target="_blank" (click)="ftuxStepCtaClick($event,3)">
                <i class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z" stroke="#DC5A4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.9165 7.47107C7.9165 7.07333 7.9165 6.87446 7.99962 6.76344C8.07206 6.66669 8.18293 6.60616 8.30349 6.59755C8.44182 6.58767 8.60911 6.6952 8.94368 6.91028L12.8775 9.43917C13.1678 9.62581 13.313 9.71913 13.3631 9.83778C13.4069 9.94146 13.4069 10.0585 13.3631 10.1621C13.313 10.2808 13.1678 10.3741 12.8775 10.5607L8.94368 13.0896C8.60911 13.3047 8.44182 13.4123 8.30349 13.4024C8.18293 13.3938 8.07206 13.3332 7.99962 13.2365C7.9165 13.1255 7.9165 12.9266 7.9165 12.5288V7.47107Z" stroke="#DC5A4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                Show me how
              </a>
            </div>
          </p-accordionTab>
          <p-accordionTab header="" [ngClass]="{'p-accordion-tab-complete': ftuxArr[4]}">
            <ng-template pTemplate="header">
              <div class="content_sec">
                  <span class="circle_icon">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8.5" cy="8.5" r="8.5" fill="#E0E1E5"/>
                      <path d="M12.0075 6.05493L7.33252 10.7299L5.20752 8.60493" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                Checkout and save
              </div>
            </ng-template>
            Review your quote, make any necessary changes, and checkout directly on Minoan.
            <div class="btn_sec">
              <a class="icon_text_btn" [href]="gettingStartedService.getStepLink(4)" target="_blank" (click)="ftuxStepCtaClick($event,4)">
                <i class="icon">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z" stroke="#DC5A4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.9165 7.47107C7.9165 7.07333 7.9165 6.87446 7.99962 6.76344C8.07206 6.66669 8.18293 6.60616 8.30349 6.59755C8.44182 6.58767 8.60911 6.6952 8.94368 6.91028L12.8775 9.43917C13.1678 9.62581 13.313 9.71913 13.3631 9.83778C13.4069 9.94146 13.4069 10.0585 13.3631 10.1621C13.313 10.2808 13.1678 10.3741 12.8775 10.5607L8.94368 13.0896C8.60911 13.3047 8.44182 13.4123 8.30349 13.4024C8.18293 13.3938 8.07206 13.3332 7.99962 13.2365C7.9165 13.1255 7.9165 12.9266 7.9165 12.5288V7.47107Z" stroke="#DC5A4B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </i>
                Show me how
              </a>
            </div>
          </p-accordionTab>
        </p-accordion>
    </div>

    <div class="bottom_sec">
      <div class="help_card">
        <!--<h5>NEED HELP?</h5>-->
        <div class="help_step_card">
          <div class="card_ui">
            <div class="icon">
              <img src="/assets/images/ic_how_mob.svg" alt="" />
            </div>
            <div class="content_wrap">
              <label>How Minoan works</label>
              <p>Get a refresher on how to shop and save with Minoan
                <i class="arrow_icon"><img src="/assets/images/ic_left.svg" alt="" /> </i>
              </p>
            </div>
            <img class="ms-auto" src="/assets/images/nav-arrow-up.svg" alt="" />
            <a class="anchor_link" target="_blank" routerLink="/onboarding/welcome" [queryParams]="{showOnboarding: true}" (click)="amplitudeService.ftuxCardHelpClick('how_minoan_works',userInfo)"></a>
          </div>
          <div class="card_ui">
            <div class="icon">
              <img src="/assets/images/ic_add_mob.svg" alt="" />
            </div>
            <div class="content_wrap">
              <label>Finish setting up your account</label>
              <p>Sign up for a 30 minute demo call with our team
                <i class="arrow_icon"><img src="/assets/images/ic_left.svg" alt="" /> </i>
              </p>
            </div>
            <img class="ms-auto" src="/assets/images/nav-arrow-up.svg" alt="" />
            <a class="anchor_link"  routerLink="/management/account" (click)="gettingBack()"></a>
          </div>
          <div class="card_ui mb-0">
            <div class="icon">
              <img src="/assets/images/create_icon_mob.svg" alt="" />
            </div>
            <div class="content_wrap">
              <label>Create a free Showroom </label>
              <p>Make your property shoppable for guests and earn commissions on your sales
                <i class="arrow_icon"><img src="/assets/images/ic_left.svg" alt="" /> </i>
              </p>
            </div>
            <img class="ms-auto" src="/assets/images/nav-arrow-up.svg" alt="" />
            <a class="anchor_link" routerLink="/showroom" (click)="gettingBack()"></a>
          </div>

          <div class="do_no_show" *ngIf="userInfo?.account?.showFtux">
            <button (click)="dontShowAgainConfirmation()" class="btn m-0 p-0 border-0"><img src="assets/images/do-not-show.svg"> Don’t show this again</button>
          </div>

        </div>

      </div>
      <!--<div class="help_card">
        &lt;!&ndash;<h5>SETTING UP YOUR ACCOUNT</h5>&ndash;&gt;
        <div class="help_step_card">
          <div class="card_ui">
            <div class="icon">
              <img src="/assets/images/ic_invite.svg" alt="" />
            </div>
            <div class="content_wrap">
              <label>Invite your team</label>
              <p>Add additional users to collaborate on Minoan
                <i class="arrow_icon"><img src="/assets/images/ic_left.svg" alt="" /> </i>
              </p>
            </div>
            <a class="anchor_link" routerLink="/management/users" [queryParams]="{add: true}" (click)="gettingBack(); amplitudeService.ftuxCardSetupClick('invite_team',userInfo)"></a>
          </div>
          <div class="card_ui">
            <div class="icon">
              <img src="/assets/images/ic_add.svg" alt="" />
            </div>
            <div class="content_wrap">
              <label>Add your properties</label>
              <p>Stay organized by setting up each of your properties
                <i class="arrow_icon"><img src="/assets/images/ic_left.svg" alt="" /> </i>
              </p>
            </div>
            <a class="anchor_link" routerLink="/management/properties" [queryParams]="{add: true}" (click)="gettingBack(); amplitudeService.ftuxCardSetupClick('add_properties',userInfo)"></a>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</div>
