import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../services/auth/auth.service";
import {RandomService} from "../services/misc/random.service";

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard {

  constructor(
    public router: Router,
    public auth: AuthService,
    public randomService: RandomService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.isUserOnboarding) {
      const key = route.queryParams['key'] ? `?key=${route.queryParams['key']}` : ''
      // if (this.randomService.isWindowWidthGreater()){
      //   console.log('Desktop');
      //   return this.router.parseUrl(`/onboarding/welcome${key}`);
      // } else {
      //   console.log('Mobile');
      //   return this.router.parseUrl(`/onboarding/how-it-works${key}`);
      // }
      return this.router.parseUrl(`/onboarding/welcome${key}`);
    }
    return true;
  }

}
