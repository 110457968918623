import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {BehaviorSubject, catchError, map, Observable, Subject, take} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {selfServeProperty, SHOWROOM_SETUP_UPDATE} from "../../interfaces/property.type";

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  constructor(
    public http: HttpClient,
  ) { }

  PROPERTY_LIST = new BehaviorSubject<selfServeProperty[]>([]);
  PROPERTY_LIST_COUNT = new BehaviorSubject<number>(0);
  showroomStatus = new Subject<boolean>();

  /**
   * [POST] API to fetch list of properties
   * */
  getListOfProperties(reqBody:{skip: number, limit: number}): Observable<{ propertyListWithoutShowroom: selfServeProperty[], count: number }> {
    return this.http.post(`${environment.apiUrl}/showroom/property/list`, reqBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }


  /**
   * [POST] API to update showroom name
   * @param requestBody Request body
   */
  updateShowroomInfo(requestBody: SHOWROOM_SETUP_UPDATE) {
    return this.http.post(`${environment.apiUrl}/showroom/setup`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * [GET] API to get the progress of showroom
   * */
  getPropertyProgress(propertyId: number) {
    return this.http.get(`${environment.apiUrl}/showroom/setup/progress/check?propertyId=${propertyId}`, {}).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * [POST] API to update property info
   */
  updatePropertyInfo(requestBody : any){
    return this.http.post(`${environment.apiUrl}/showroom/update`, requestBody).pipe(
      take(1),
      map((response: any) => response.data),
      catchError((err) => {
        throw err;
      })
    )
  }

  /**
   * [POST] API to update showroom published status
   */
  updateShowroomStatus(requestBody : any){
    return this.http.post(`${environment.apiUrl}/update/showroom/status`, requestBody).pipe(
      take(1),
      map((response: any) => response.data),
      catchError(err => {
        throw err
      })
    )
  }

}
