<!--LOOM VIDEO MODAL START-->
<p-dialog [showHeader]="false" [(visible)]="loomModal" [modal]="true" [baseZIndex]="10000"
          [draggable]="false"  [dismissableMask]="true" [resizable]="false"
          [ngClass]="{'shop_browse_video': loomContent === 1}"
          [styleClass]="loomContent === 1 ? 'ftux_modal shop_browse_video' : 'ftux_modal'"
          (onShow)="onShowLoomVideoModal()" (onHide)="onHideLoomVideoModal()" maskStyleClass="mask-class-2 ">
  <div *ngIf="!!loomContent && !videoUrl" class="modal-content" [innerHTML]="domSanitizer.bypassSecurityTrustHtml(loomVideoEmbededLink)"></div>
  <div *ngIf="videoUrl" class="modal-content" [innerHTML]="domSanitizer.bypassSecurityTrustHtml(videoUrl)"></div>
</p-dialog>
<!--LOOM VIDEO MODAL END-->
