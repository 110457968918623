@if (showLoader) {
  <div class="loader_wrapper">
    <div class="loader_positioning">
      <div class="loader2"></div>
      @if (loaderText) {
        <div class="text_loader">{{loaderText}}</div>
      }
    </div>
  </div>
}
