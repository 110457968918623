import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {formValidators} from "../../../../utils/form-utils";
import {BRANDS_EXCLUDED_FROM_SCRAPING, PLACEHOLDER_URL, URL_REGEX} from "../../../../constants/constants";
import {RandomService} from "../../../../services/misc/random.service";
import {ErrorsService} from "../../../../services/customer-onboarding/errors.service";
import {CartService} from "../../../../services/cart/cart.service";
import {debounceTime, Subscription} from "rxjs";
import {AmplitudeService} from "../../../../services/events/amplitude.service";
import {AuthService} from "../../../../services/auth/auth.service";
import {CART_WISHLIST_QUOTE} from "../../../../interfaces/customTypes";
import {QuoteListType} from "../../../../interfaces/quoteList.type";

interface newItemForm {
  url: FormControl<string | null>
  productName: FormControl<string | null>,
  variant: FormControl<string | null>,
  quantity: FormControl<number | null>,
  noVariant: FormControl<boolean | null>,
  productImageUrl: FormControl<string | null>,
  imgSelected: FormControl<number | null>
}

export interface addProductDetails {
  url: string;
  productName: string,
  variant: string,
  quantity: number,
  productImageUrl: string,
  prePopulatedName: string,
  prePopulatedVariant: string,
  prePopulatedImageCount: number,
  brandName: string,
  addProductInfo?: CART_WISHLIST_QUOTE | 'new-quote' | undefined,
  newQuoteInfo?: QuoteListType
  quoteList?: any[]
}

/**
 * Add Item Modal
 */
@Component({
  selector: 'add-item-modal',
  templateUrl: './add-item-modal.component.html',
  styleUrls: ['./add-item-modal.component.scss']
})
export class AddItemModalComponent implements OnInit, OnChanges {

  @Input() addProductModal: boolean = false;
  @Output() addProductModalChange = new EventEmitter<boolean>();


  // For heading
  @Input() type: CART_WISHLIST_QUOTE | 'mobile-navbar' = 'quote'

  // API Call status
  @Input() apiCallOn: boolean = false;

  variantPlaceholder: string = 'ex: Queen size, charcoal, aged oak legs'

  urlModal: boolean = false
  detailsFetched: boolean = false
  images: string[] = []

  // Add product form
  addProductForm: FormGroup = new FormGroup<newItemForm>({
    url: new FormControl('', {
      validators: [Validators.required, formValidators.noWhiteSpace, Validators.pattern(URL_REGEX)],
      asyncValidators: [],
      updateOn: 'change'
    }),
    productName: new FormControl('', [Validators.required, formValidators.noWhiteSpace]),
    variant: new FormControl('', [Validators.required, formValidators.noWhiteSpace]),
    quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
    noVariant: new FormControl(false),
    productImageUrl: new FormControl('', []),
    imgSelected: new FormControl(-1, []),
  })

  // For subscriptions on form value changes
  listOfSubscriptions: Subscription[] = [];

  // Returns value of add item form
  @Output() submitProductEvent = new EventEmitter<addProductDetails>()

  // To apply/remove minoan partner validation from URL
  @Input() minoanPartnerValidation: boolean = true;

  modalLoader: boolean = false;

  scrapingCalled: boolean = false;
  showSkeleton: boolean = false;

  urlForm: FormGroup = new FormGroup<{ url: FormControl<string | null> }>({
    url: new FormControl('', {
      validators: [Validators.required, formValidators.noWhiteSpace, Validators.pattern(URL_REGEX)],
      asyncValidators: [],
      updateOn: 'change'
    })
  })

  brandPartnerFetched: boolean = false;
  brandPartnerError: boolean = false;

  scrapingData = {
    prePopulatedName: '',
    prePopulatedVariant: '',
    prePopulatedImageCount: 0,
    brandName: '',
  }

  // List of active quotes
  activeQuotesList: QuoteListType[] = [];
  selectedQuote: QuoteListType | null = null;

  nextButtonClicked: boolean = false;

  constructor(
    public randomService: RandomService,
    public errorService: ErrorsService,
    public cartService: CartService,
    public amplitudeService: AmplitudeService,
    public auth: AuthService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['addProductModal']) {
      this.modalLoader = false;
      // If add product modal is opened, open URL modal, else close both modals
      if (changes['addProductModal'].currentValue) {
        this.urlModal = true;
        this.detailsFetched = false;
        this.scrapingData = {
          prePopulatedName: '',
          prePopulatedVariant: '',
          prePopulatedImageCount: 0,
          brandName: '',
        }

        this.addItemModalOpenEvent();
        this.subscribeToFormChanges();

        if(this.type === 'mobile-navbar') {
          // Get cart options
          this.cartService.getCartOptions().subscribe({
            next: (response) => {
              //Quote list
              const activeQuotes = ['draft', 'draft_quote', 'quote_requested', 'quote_updated', 'quote_modified', 'quote_request_updated', 'quote_outdated'];
              this.activeQuotesList = response.quoteList.filter((each: QuoteListType) => activeQuotes.includes(each.orderB2BStatus));
            }
          })

        }


      } else {
        this.detailsFetched = false;
        this.urlModal = false;
      }
    }
  }

  /**
   * Modal close event handler
   */
  onAddProductModalHide() {
    this.addProductForm.reset(this.addItemFormResetObject);
    if(!this.urlModal) {
      this.addProductModalChange.emit(false);
      this.removeAllSubscriptions()
    }
    this.detailsFetched = false;
    this.images = [];
  }

  /**
   * Getter for add product form controls
   */
  get addProductFormControls(): { [p: string]: AbstractControl<any, any> } {
    return this.addProductForm.controls
  }

  /**
   * Submit add product form event handler
   */
  submitAddProduct(submitType?: CART_WISHLIST_QUOTE | 'new-quote') {
    const formData = this.addProductForm.getRawValue();
    if (this.addProductForm.valid) {
      if (this.minoanPartnerValidation) {
        // Partner validation followed by emit event
        const requestBody = {
          url: formData.url
        }
        this.brandPartnerFetched = true;
        this.cartService.checkMinoanPartner(requestBody).subscribe({
          next: (response: any) => {
            this.brandPartnerFetched = false;
            const data = response.data;
            if (data.isMinoanPartner) {
              this.brandPartnerError = false;
              this.submitProductEvent.emit({...formData, ...this.scrapingData, addProductInfo: submitType, newQuoteInfo: submitType === 'quote' ? this.selectedQuote : undefined})
            } else {
              this.brandPartnerError = true;
              this.addItemUploadClickErrorEvent({...formData, ...this.scrapingData})
            }
          },
          error: () => {
            this.brandPartnerFetched = false;
            this.brandPartnerError = true;
            this.addItemUploadClickErrorEvent({...formData, ...this.scrapingData})
          }
        })
      } else {
        // Emit without partner validation
        this.submitProductEvent.emit({...formData, ...this.scrapingData, addProductInfo: submitType})
      }
    } else {
      this.addProductForm.markAllAsTouched();
      this.addItemUploadClickErrorEvent({...formData, ...this.scrapingData})
    }
  }

  /**
   * Submit the form on Enter event handler
   * @param event Keyboard Event
   */
  onKeyUpEventHandler(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      if (!this.apiCallOn) {
        if(!this.scrapingCalled && !this.brandPartnerFetched) {
          this.submitAddProduct();
        }
      } else {
        this.addProductForm.reset(this.addItemFormResetObject);
      }
    }
  }

  /**
   * Scrape product data on Enter
   * @param event Keyboard Event
   */
  onURLKeyUp(event: KeyboardEvent) {
    if (event.key == 'Enter' && !this.brandPartnerFetched) {
      this.submitProductUrl(event);
    }
  }

  /**
   * When URL modal is closed, send addProduct emit event as false only if closed from 1st modal itself
   */
  onURLModalHide() {
    if (!this.detailsFetched) {
      this.addProductModalChange.emit(false);
      this.addProductForm.reset(this.addItemFormResetObject);
    }
    this.urlForm.reset({
      url: ''
    })
    this.brandPartnerError = false;
    this.brandPartnerFetched = false;
    this.nextButtonClicked = false;
  }

  /**
   * Call scraping data and fetch product information
   */
  scrapeProductData(clickEvent?: boolean) {
    const form = this.detailsFetched ? this.addProductForm : this.urlForm
    const formData = form.getRawValue();
    const conditionForApiCall = this.detailsFetched ? this.addProductFormControls['url'].valid : this.urlForm.valid;
    if (conditionForApiCall && !this.brandPartnerError) {

      const requestBody = {
        url: formData.url
      }
      this.modalLoader = true;
      this.scrapingCalled = true;
      this.showSkeleton = true;
      this.cartService.scrapProductData(requestBody).subscribe({
        next: async (response: any) => {
          const {title, images, url} = response;
          let validImages: string[] = []

          this.images = [PLACEHOLDER_URL];

          // Make an image a promise
          const createAPromise = (imgLink: string): Promise<string> => {
            return new Promise<string>((resolve, reject) => {
              const i = new Image();
              i.onload = () => resolve(imgLink)
              i.onerror = () => resolve('')
              i.src = imgLink;
            })
          }

          if(!!images.length) {
            const first = createAPromise(images[0])
            first.then((firstResponse: string) => {
              if(this.images.length && this.images[0] === PLACEHOLDER_URL && firstResponse) {
                validImages.push(firstResponse)
                this.images = [firstResponse]
                this.scrapingCalled = false;
              }
            }).catch((e) => {
              console.log('First image error', e)
            }).finally(() => {
              const promises = images.map((each: string) => createAPromise(each))
              // After all promises are settled, remove skeleton and set other things
              Promise.allSettled(promises).then((promiseResponse) => {
                promiseResponse.forEach((each) => {
                  if(each.status === 'fulfilled' && each.value) {
                    validImages.push(each.value)
                  }
                })
                this.images = validImages.length == 0 ? [PLACEHOLDER_URL] : [...validImages]
                this.scrapingData = {
                  prePopulatedName: title,
                  prePopulatedVariant: '',
                  prePopulatedImageCount: validImages.length,
                  brandName: this.scrapingData.brandName,
                }
                this.scrapingCalled = false;
                this.showSkeleton = false;
              }).catch(() => {
                this.scrapingCalled = false;
                this.showSkeleton = false;
              })
            })

          } else {
            this.scrapingData = {
              prePopulatedName: title,
              prePopulatedVariant: '',
              prePopulatedImageCount: 0,
              brandName: this.scrapingData.brandName,
            }
            this.scrapingCalled = false;
            this.showSkeleton = false;
          }



          // const promises: Promise<string>[] = images.map((eachImage: string) => {
          //   return new Promise<string>((resolve, reject) => {
          //     const i = new Image();
          //     i.onload = () => resolve(eachImage)
          //     i.onerror = () => resolve('')
          //     i.onloadstart = () => console.log('loading started', eachImage)
          //     i.src = eachImage;
          //   });
          // })
          //
          // // Fire first image and then settle for all other images
          // if(!!promises.length) {
          //   promises[0].then((firstResponse: string) => {
          //     if(this.images.length && this.images[0] === PLACEHOLDER_URL && firstResponse) {
          //       this.images = [firstResponse]
          //       this.scrapingCalled = false;
          //     }
          //   }).catch((e) => {
          //     console.log('First image error', e)
          //   }).finally(() => {
          //     console.log('finally')
          //     // After all promises are settled, remove skeleton and set other things
          //     Promise.allSettled(promises).then((promiseResponse) => {
          //       promiseResponse.forEach((each) => {
          //         if(each.status === 'fulfilled' && each.value) {
          //           validImages.push(each.value)
          //         }
          //       })
          //       this.images = validImages.length == 0 ? [PLACEHOLDER_URL] : [...validImages]
          //       this.scrapingData = {
          //         prePopulatedName: title,
          //         prePopulatedVariant: '',
          //         prePopulatedImageCount: validImages.length,
          //         brandName: this.scrapingData.brandName,
          //       }
          //       this.showSkeleton = false;
          //     }).catch(() => {
          //       this.showSkeleton = false;
          //     })
          //   })
          // } else {
          //   this.scrapingData = {
          //     prePopulatedName: title,
          //     prePopulatedVariant: '',
          //     prePopulatedImageCount: 0,
          //     brandName: this.scrapingData.brandName,
          //   }
          //   this.scrapingCalled = false;
          //   this.showSkeleton = false;
          // }


          this.addProductForm.patchValue({
            url: requestBody.url,
          }, {emitEvent: false});
          this.addProductForm.patchValue({
            productName: title,
            variant: '',
            noVariant: false,
          })

          this.setUrlSubscription();

          this.urlModal = false;
          this.detailsFetched = true;
          this.modalLoader = false;
          this.addItemUrlClickEvent(true, clickEvent)
        },
        error: (err) => {
          this.addProductForm.patchValue({
            url: requestBody.url,
            productName: '',
            variant: '',
            noVariant: false
          }, {emitEvent: false});
          this.addProductFormControls['variant'].enable();

          this.images = [PLACEHOLDER_URL]
          this.urlModal = false;
          this.detailsFetched = true;
          this.modalLoader = false;
          this.addItemUrlClickEvent(false, clickEvent, {message: err})
          this.scrapingCalled = false;
        }
      })
    }
  }

  /**
   * Event handler for main image change
   * @param event Image URL
   */
  mainImageChangedEventHandler(event: { imgUrl: string, imgNumber: number }) {
    this.addProductForm.patchValue({
      productImageUrl: event.imgUrl,
      imgSelected: event.imgNumber
    })
  }

  /**
   * add_item_url_next_click Amplitude event on calling scrapping API
   * @param success API Success/Failure
   * @param clickEvent Click Event
   * @param errorMessage API Failure Error
   */
  addItemUrlClickEvent(success: boolean, clickEvent?: boolean ,errorMessage?: any) {
    if(clickEvent) {
      const formData = this.addProductForm.getRawValue();
      const urlFormData = this.urlForm.getRawValue()
      const url = formData.url ? formData.url : urlFormData.url
      const eventProps = {
        url: url,
        success: success,
        user_id: this.userValue?.account.id,
        account_id: this.userValue?.account.activeCompanyId,
        error_message: success ? undefined : errorMessage ? errorMessage : this.brandPartnerError ? {message: 'not_brand_partner'} : {message: 'invalid_url'},
      }
      this.amplitudeService.trackEvent('add_item_url_next_click', eventProps)
    }
  }

  /**
   * Add item modal open event
   */
  addItemModalOpenEvent() {
    const eventType = 'add_item_start_click';
    const eventProps = {
      added_to: this.type,
      user_id: this.userValue?.account.id,
      account_id: this.userValue?.account.activeCompanyId
    }
    this.amplitudeService.trackEvent(eventType, eventProps)
  }

  /**
   * Submits the pasted URL an checks whether it belongs to Minoan Partner or not. Then creates amplitude event for any errors
   */
  submitProductUrl(event?: any) {
    const form = this.detailsFetched ? this.addProductForm : this.urlForm
    this.nextButtonClicked = !!event;
    const conditionForApiCall = this.detailsFetched ? this.addProductFormControls['url'].valid : this.urlForm.valid;
    if (conditionForApiCall) {
      const requestBody = {
        url: form.getRawValue().url
      };
      this.brandPartnerFetched = true;
      this.modalLoader = true;
      this.cartService.checkMinoanPartner(requestBody).subscribe({
        next: (response: any) => {
          this.brandPartnerFetched = false;
          const data = response.data;
          this.brandPartnerError = this.minoanPartnerValidation && !data.isMinoanPartner
          this.modalLoader = !this.brandPartnerError;
          this.scrapingData.brandName = data.brandName
          if(this.brandPartnerError) {
            this.addItemUrlClickEvent(false, !!event)
          } else {
            this.excludeBrandsAndScrape(data, requestBody, !!event)
          }
        },
        error: () => {
          this.hideModalLoader()
          this.brandPartnerError = true;
          this.brandPartnerFetched = false;
        }
      })
    } else {
      this.addItemUrlClickEvent(false, !!event)
      this.urlForm.markAllAsTouched();
    }
  }

  /**
   * User Value from auth
   */
  get userValue() {
    return this.auth.user.getValue()
  }

  /**
   * Calls event for add item upload click on error
   * @param requestBody Request body
   */
  addItemUploadClickErrorEvent(requestBody: any) {
    const error = {
      product_name: this.addProductFormControls['productName'].invalid,
      product_url: this.addProductFormControls['url'].invalid || this.brandPartnerError,
      product_variant: this.addProductFormControls['variant'].invalid,
    }
    this.amplitudeService.addItemEvent(false, requestBody, this.type, this.userValue, error)
  }

  /**
   * Reset object for Add Item form
   */
  get addItemFormResetObject() {
    return {
      url: '',
      productName: '',
      variant: '',
      quantity: 1,
      noVariant: false,
      productImageUrl: '',
      imgSelected: -1,
    }
  }

  /**
   * Create subscription for url change in add item modal
   */
  setUrlSubscription() {
    if(!this.detailsFetched) {
      const urlPartnerFetchSubscription = this.addProductFormControls['url']?.valueChanges.pipe().subscribe({
        next: () => {
          if(this.addProductFormControls['url'].valid) {
            this.minoanPartnerValidation ? this.brandPartnerFetched = true : this.scrapingCalled = true
          }
        }
      })
      this.listOfSubscriptions.push(urlPartnerFetchSubscription)

      const urlSubmitSubscription = this.addProductFormControls['url']?.valueChanges.pipe(debounceTime(500)).subscribe({
        next: () => {
          this.submitProductUrl();
        }
      })
      this.listOfSubscriptions.push(urlSubmitSubscription)
    }
  }

  /**
   * Exclude brands like C&B and then scrape data
   * @param data Response Data
   * @param requestBody Request Body
   * @param event Click / Keyboard event
   */
  excludeBrandsAndScrape(data: any, requestBody: any, event: boolean) {
    if(!BRANDS_EXCLUDED_FROM_SCRAPING.includes(data.brandId)) {
      this.scrapeProductData(event)
    } else {
      this.scrapingData = {
        prePopulatedName: '',
        prePopulatedVariant: '',
        prePopulatedImageCount: 0,
        brandName: data.brandName
      }
      this.scrapingCalled = false;
      this.addProductForm.patchValue({
        url: requestBody.url,
      }, {emitEvent: false})
      this.addProductForm.patchValue({
        productName: '',
        variant: '',
        noVariant: false,
      })

      this.images = [PLACEHOLDER_URL];
      this.setUrlSubscription();
      this.urlModal = false;
      this.detailsFetched = true;
      this.hideModalLoader()
      this.addItemUrlClickEvent(true, event)
    }
  }

  /**
   * Hide modal loader after set time
   * @param time Time in ms
   */
  hideModalLoader(time: number = 200) {
    setTimeout(() => {
      this.modalLoader = false
    }, time)
  }

  /**
   * Take user back to URL step
   */
  backToUrlStep() {
    this.urlForm.reset({
      url: ''
    })
    this.detailsFetched = false
    this.removeAllSubscriptions()
    this.subscribeToFormChanges()
    this.urlModal = true
  }

  /**
   * Create value change subscriptions for forms
   */
  subscribeToFormChanges() {
    const noVariantSubscription = this.addProductFormControls['noVariant'].valueChanges.subscribe({
      next: (val) => {
        if (val) {
          this.addProductFormControls['variant'].setValue('N/A')
          this.addProductFormControls['variant'].disable()
        } else {
          this.addProductFormControls['variant'].setValue('')
          this.addProductFormControls['variant'].enable()
        }
        this.addProductFormControls['variant'].setValidators(val ? [] : [Validators.required, formValidators.noWhiteSpace])
        this.addProductFormControls['variant'].updateValueAndValidity()
      }
    })
    this.listOfSubscriptions.push(noVariantSubscription)

    const urlValueSubscription = this.urlForm.controls['url']?.valueChanges.subscribe({
      next: () => {
        this.brandPartnerError = false
      }
    })
    this.listOfSubscriptions.push(urlValueSubscription)

    const urlValueSubscriptionAddForm = this.addProductFormControls['url']?.valueChanges.subscribe({
      next: () => {
        this.brandPartnerError = false;
      }
    })
    this.listOfSubscriptions.push(urlValueSubscriptionAddForm)
  }

  /**
   * Add subscriptions again after
   */
  removeAllSubscriptions() {
    while (this.listOfSubscriptions.length > 0) {
      this.listOfSubscriptions.pop()?.unsubscribe();
    }
  }

  /**
   * Submit product details for header modal
   */
  submitCommonAddProductModal(type: CART_WISHLIST_QUOTE | 'new-quote') {
    this.minoanPartnerValidation = ['cart', 'quote', 'new-quote'].includes(type)
    this.submitAddProduct(type)
  }

  /**
   * Dropdown open/close change event handler
   * @param event Change event (true if dropdown is opened, else false)
   */
  onChangeDropdown(event: boolean) {
    this.selectedQuote = null
  }

  get disableAddButton() {
    return this.brandPartnerFetched || this.brandPartnerError || this.apiCallOn || this.scrapingCalled;
  }

  /**
   * Add p-overflow-hidden class on modal show as it disappears when going from modal 1 to modal 2
   */
  onModalShow() {
    setTimeout(() => {
      if(!document.body.classList.contains('p-overflow-hidden')) {
        document.body.classList.add('p-overflow-hidden')
      }
    }, 400)
  }

}
