import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = () => {};

  // Overwrite console warn to remove warnings on production builds
  window.console.warn = () => {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
