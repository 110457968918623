<div [ngClass]="{'p-listbox p-component': true, 'p-disabled': disabled}" [ngStyle]="style" [class]="styleClass">
  <div class="p-listbox-header">
    <div class="p-checkbox p-component" *ngIf="checkbox && multiple && showToggleAll && filterValue == ''" [ngClass]="{ 'p-checkbox-disabled': disabled || toggleAllDisabled }">
      <div class="p-hidden-accessible" *ngIf="filterValue == ''">
        <input type="checkbox" readonly="readonly" [checked]="allChecked" (focus)="onHeaderCheckboxFocus()" (blur)="onHeaderCheckboxBlur()" (keydown.space)="toggleAll($event)" [disabled]="disabled || toggleAllDisabled" />
      </div>
      <div #headerchkbox class="p-checkbox-box" *ngIf="filterValue == ''" [ngClass]="{ 'p-highlight': allChecked, 'p-focus': headerCheckboxFocus, 'p-disabled': disabled || toggleAllDisabled }" (click)="toggleAll($event)">
        <span class="p-checkbox-icon" [ngClass]="{ 'pi pi-check': allChecked }"></span>
      </div>
      <div class="header-content" *ngIf="filterValue == ''" [ngClass]="{ 'p-highlight': allChecked, 'p-focus': headerCheckboxFocus, 'p-disabled': disabled || toggleAllDisabled }" (click)="toggleAll($event)">
        Select All
      </div>
    </div>
    <div *ngIf="filterValue != ''" class="spacer22" style="height: 22px"></div>
    <ng-container *ngIf="filterTemplate; else builtInFilterElement">
      <ng-container *ngTemplateOutlet="filterTemplate; context: { options: filterOptions }"></ng-container>
    </ng-container>
    <ng-template #builtInFilterElement>
      <div class="p-listbox-filter-container" *ngIf="filter">
        <input
          #filter
          type="text"
          [value]="filterValue || ''"
          (input)="onFilter($event)"
          class="p-listbox-filter p-inputtext p-component"
          [disabled]="disabled"
          [attr.placeholder]="filterPlaceHolder"
          [attr.aria-label]="ariaFilterLabel"
        />
        <span class="p-listbox-filter-icon pi" (click)="clearFilter()" [ngClass]="{'pi-times': filterValue != '', 'pi-search': filterValue == '' }"></span>
      </div>
    </ng-template>
  </div>
  <div [ngClass]="'p-listbox-list-wrapper'" [ngStyle]="listStyle" [class]="listStyleClass">
    <ul class="p-listbox-list" role="listbox" [attr.aria-multiselectable]="multiple">
      <ng-container *ngIf="!group">
        <ng-container *ngTemplateOutlet="itemslist; context: { $implicit: optionsToRender }"></ng-container>
      </ng-container>
      <ng-template #itemslist let-optionsToDisplay>
        <cdk-virtual-scroll-viewport *ngIf="virtualScroll && !isEmpty(); else noVirtualScroll" #itemListScroller [ngStyle]="listStyle" [orientation]="'vertical'" [itemSize]="itemSize">
          <li
            *cdkVirtualFor="let option of optionsToDisplay; let i = index"
            [attr.tabindex]="disabled || isOptionDisabled(option) ? null : '0'"
            pRipple
            [ngClass]="{ 'p-listbox-item': true, 'p-highlight': isSelected(option), 'p-disabled': this.isOptionDisabled(option) }"
            role="option"
            [attr.aria-label]="getOptionLabel(option)"
            [attr.aria-selected]="isSelected(option)"
            (click)="onOptionClick($event, option)"
            (dblclick)="onOptionDoubleClick($event, option)"
            (touchend)="onOptionTouchEnd(option)"
            (keydown)="onOptionKeyDown($event, option)"
          >
            <div class="p-checkbox p-component" *ngIf="checkbox" [ngClass]="{ 'p-checkbox-disabled': disabled || isOptionDisabled(option) }">
              <div class="p-checkbox-box" [ngClass]="{ 'p-highlight': isSelected(option) }">
                <span class="p-checkbox-icon" [ngClass]="{ 'pi pi-check': isSelected(option) }"></span>
              </div>
            </div>
            <span *ngIf="!itemTemplate">{{ getOptionLabel(option) }}</span>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: i }"></ng-container>
          </li>
        </cdk-virtual-scroll-viewport>
        <ng-template #noVirtualScroll>
          <li
            *ngFor="let option of optionsToDisplay; let i = index"
            [attr.tabindex]="disabled || isOptionDisabled(option) ? null : '0'"
            pRipple
            [ngClass]="{ 'p-listbox-item': true, 'p-highlight': isSelected(option), 'p-disabled': this.isOptionDisabled(option) }"
            role="option"
            [attr.aria-label]="getOptionLabel(option)"
            [attr.aria-selected]="isSelected(option)"
            (click)="onOptionClick($event, option)"
            (dblclick)="onOptionDoubleClick($event, option)"
            (touchend)="onOptionTouchEnd(option)"
            (keydown)="onOptionKeyDown($event, option)"
          >
            <div class="p-checkbox p-component" *ngIf="checkbox" [ngClass]="{ 'p-checkbox-disabled': disabled || isOptionDisabled(option) }">
              <div class="p-checkbox-box" [ngClass]="{ 'p-highlight': isSelected(option) }">
                <span class="p-checkbox-icon" [ngClass]="{ 'pi pi-check': isSelected(option) }"></span>
              </div>
            </div>
            <span *ngIf="!itemTemplate">{{ getOptionLabel(option) }}</span>
            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: i }"></ng-container>
          </li>
        </ng-template>
      </ng-template>
      <li *ngIf="hasFilter() && isEmpty()" class="p-listbox-empty-message">
        <ng-container *ngIf="!emptyFilterTemplate && !emptyTemplate">
          {{ emptyFilterMessageLabel }}
        </ng-container>
        <ng-container #emptyFilter *ngTemplateOutlet="emptyFilterTemplate || emptyTemplate"></ng-container>
      </li>
      <li *ngIf="!hasFilter() && isEmpty()" class="p-listbox-empty-message">
        <ng-container *ngIf="!emptyTemplate">
          {{ emptyMessageLabel }}
        </ng-container>
        <ng-container #empty *ngTemplateOutlet="emptyTemplate"></ng-container>
      </li>
    </ul>
  </div>
  <div class="p-listbox-footer" *ngIf="footerFacet || footerTemplate">
    <ng-content select="p-footer"></ng-content>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </div>
</div>
