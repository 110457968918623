import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AccountNameService {
  accountInfoSource = new BehaviorSubject<{ companyName: string | null, accountType: string | null }>({ companyName: null, accountType: null });
  accountInfo = this.accountInfoSource.asObservable();
  constructor() { }
  setAccountDetails(companyName: string, accountType: string) {
    this.accountInfoSource.next({ companyName, accountType });
  }
}
