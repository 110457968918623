import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PluralSingularPipe} from './pluralSingular/plural-singular.pipe';
import {ApplyFnPipe} from './applyFn/apply-fn.pipe';
import {GroupedArrayPipe} from './grouped-array.pipe';
import {ReplaceHttpsPipe} from './replaceHttps/replace-https.pipe';
import {ImageSizePipe} from "./imageSize/image-size.pipe";
import {FilterArrayPipe} from "./filterArray/filter-array.pipe";


@NgModule({
  declarations: [
    PluralSingularPipe,
    ApplyFnPipe,
    GroupedArrayPipe,
    ReplaceHttpsPipe,
    ImageSizePipe,
    FilterArrayPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PluralSingularPipe,
    ApplyFnPipe,
    GroupedArrayPipe,
    ReplaceHttpsPipe,
    ImageSizePipe,
    FilterArrayPipe,
  ],
  providers:[ReplaceHttpsPipe]
})
export class PipesModule { }
