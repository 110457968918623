const baseImageUrl = 'https://storage.googleapis.com/minoan-images/products';
const productList = [
  {
    "productId": "compact_desk",
    "order": 1,
    "brandName": "Burrow",
    "productNameComplete": "Index Desk",
    "productNameAbbreviated": "Compact Desk",
    "pricingDiscountLine": "Save 20% off retail",
    "dollarLine": "$399",
    "strikeout": true,
    "variant": "Oak",
    "quantity": 1,
    "url": "https://burrow.com/home-office/desks/index-desk?srsltid=AfmBOooqAzM5QjkQuzy-56wgKvSuAPGmz6Xia_amzaRzaa2ouqyfah52",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/compact_desk.png`,
    "mobileImage": ""
  },
  {
    "productId": "charging_desk_lamp",
    "order": 4,
    "brandName": "AllModern",
    "productNameComplete": "Metal USB Desk Lamp",
    "productNameAbbreviated": "Charging Desk Lamp",
    "pricingDiscountLine": "Save 10% off Pro price",
    "dollarLine": "$219",
    "strikeout": true,
    "variant": "Black",
    "quantity": 1,
    "url": "https://www.allmodern.com/lighting/pdp/metal-desk-lamp-a000426031.html?piid%5B0%5D=508516185&piid%5B1%5D=508516184",
    "imageLink": "",
    "desktopImage":  `${baseImageUrl}/aug24/charging_desk_lamp.png`,
    "mobileImage": ""
  },
  {
    "productId": "smart_diffuser",
    "order": 5,
    "brandName": "Pura",
    "productNameComplete": "Pura 4",
    "productNameAbbreviated": "Smart Diffuser",
    "pricingDiscountLine": "Save 30% off retail",
    "dollarLine": "$50",
    "strikeout": true,
    "variant": "N/A",
    "quantity": 1,
    "url": "https://pura.com/products/device",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/smart_diffuser.png`,
    "mobileImage": ""
  },
  {
    "productId": "coffee_maker",
    "order": 6,
    "brandName": "Crate & Barrel",
    "productNameComplete": "Cuisinart ® PerfecTemp ® Stainless Steel 14-Cup Programmable Coffee Maker",
    "productNameAbbreviated": "Coffee Maker",
    "pricingDiscountLine": "Save 25% off retail",
    "dollarLine": "$100",
    "strikeout": true,
    "variant": "Stainless",
    "quantity": 1,
    "url": "https://www.crateandbarrel.com/cuisinart-perfectemp-stainless-steel-14-cup-programmable-coffee-maker/s242964",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/coffee_maker.png`,
    "mobileImage": ""
  },
  {
    "productId": "coffee",
    "order": 7,
    "brandName": "Public Goods",
    "productNameComplete": "Ground Coffee",
    "productNameAbbreviated": "Coffee",
    "pricingDiscountLine": "Save 10% off retail",
    "dollarLine": "$11",
    "strikeout": true,
    "variant": "N/A",
    "quantity": 1,
    "url": "https://www.publicgoods.com/products/fair-trade-coffee-ground-beans",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/coffee.png`,
    "mobileImage": ""
  },
  {
    "productId": "office_rug",
    "order": 8,
    "brandName": "Tumble",
    "productNameComplete": "Olivia Ivory & Neutral",
    "productNameAbbreviated": "Office Rug",
    "pricingDiscountLine": "Save 20% off retail",
    "dollarLine": "$99 - $539",
    "strikeout": true,
    "variant": "Ivory",
    "quantity": 1,
    "url": "https://www.tumbleliving.com/collections/bestsellers/products/olivia-ivory-neutral",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/office_rug.png`,
    "mobileImage": ""
  },
  {
    "productId": "standing_desk",
    "order": 10,
    "brandName": "Branch",
    "productNameComplete": "Duo Standing Desk",
    "productNameAbbreviated": "Standing Desk",
    "pricingDiscountLine": "Save 20% off retail",
    "dollarLine": "$499 - $699",
    "strikeout": true,
    "variant": "Woodgrain/white",
    "quantity": 1,
    "url": "https://www.branchfurniture.com/collections/desks/products/duo-standing-desk",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/standing_desk.png`,
    "mobileImage": ""
  },
  {
    "productId": "office_chair",
    "order": 11,
    "brandName": "Branch",
    "productNameComplete": "Ergonomic Chair",
    "productNameAbbreviated": "Office Chair",
    "pricingDiscountLine": "Save 20% off retail",
    "dollarLine": "$329",
    "strikeout": true,
    "variant": "Black",
    "quantity": 1,
    "url": "https://www.branchfurniture.com/products/ergonomic-chair",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/office_chair.png`,
    "mobileImage": ""
  },
  {
    "productId": "outlet_wall_tap",
    "order": 12,
    "brandName": "Wayfair",
    "productNameComplete": "Philips 6-Outlet Wall Tap with Surge Protection, 900J, White, SPP3461WA/37",
    "productNameAbbreviated": "6-Outlet Wall Tap",
    "pricingDiscountLine": "Save 10% off Pro price",
    "dollarLine": "$14",
    "strikeout": true,
    "variant": "N/A",
    "quantity": 1,
    "url": "https://www.wayfair.com/lighting/pdp/philips-6-outlet-wall-tap-with-surge-protection-900j-white-spp3461wa37-phi10250.html",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/outlet_wall_tap.png`,
    "mobileImage": ""
  },
  {
    "productId": "outdoor_table",
    "order": 14,
    "brandName": "Crate & Barrel",
    "productNameComplete": "Lanai 35\" Square Fliptop Outdoor Dining Table",
    "productNameAbbreviated": "Outdoor Table",
    "pricingDiscountLine": "Save 25% off retail",
    "dollarLine": "$399",
    "strikeout": true,
    "variant": "N/A",
    "quantity": 1,
    "url": "https://www.crateandbarrel.com/lanai-35-square-fliptop-outdoor-dining-table/s577414",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/outdoor_table.png`,
    "mobileImage": ""
  },
  {
    "productId": "outdoor_chair",
    "order": 15,
    "brandName": "Crate & Barrel",
    "productNameComplete": "Lanai White Mesh Square Stackable Outdoor Dining Chair with Arms",
    "productNameAbbreviated": "Outdoor Chair",
    "pricingDiscountLine": "Save 25% off retail",
    "dollarLine": "$159",
    "strikeout": true,
    "variant": "Charcoal",
    "quantity": 1,
    "url": "https://www.crateandbarrel.com/lanai-white-mesh-square-stackable-outdoor-dining-chair-with-arms/s363255",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/outdoor_chair.png`,
    "mobileImage": ""
  },
  {
    "productId": "dining_table",
    "order": 18,
    "brandName": "Wayfair",
    "productNameComplete": "Black Audna 79\" Dining Table",
    "productNameAbbreviated": "Dining Table",
    "pricingDiscountLine": "Save 10% off Pro price",
    "dollarLine": "$1,300",
    "strikeout": true,
    "variant": "Black",
    "quantity": 1,
    "url": "https://www.wayfair.com/furniture/pdp/wade-logan-audna-79-dining-table-ppma1355.html?piid=88769556",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/dining_table.png`,
    "mobileImage": ""
  },
  {
    "productId": "smart_alarm_clock",
    "order": 19,
    "brandName": "Loftie",
    "productNameComplete": "Loftie Clock",
    "productNameAbbreviated": "Smart Alarm Clock",
    "pricingDiscountLine": "Save 40% off retail",
    "dollarLine": "$150",
    "strikeout": true,
    "variant": "Black",
    "quantity": 1,
    "url": "https://byloftie.com/products/loftie?variant=32218222592047",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/smart_alarm_clock.png`,
    "mobileImage": ""
  },
  {
    "productId": "console_table",
    "order": 20,
    "brandName": "Walker Edison",
    "productNameComplete": "A-Frame Rustic Desk Console Table",
    "productNameAbbreviated": "Console Desk",
    "pricingDiscountLine": "Minoan member pricing",
    "dollarLine": "$336",
    "strikeout": true,
    "variant": "Faux Marble",
    "quantity": 1,
    "url": "https://walkeredison.com/products/a-frame-rustic-desk-1?srsltid=AfmBOop8-6rq-hj_r2-AjQ7DgQ4ocKuam5Heo7FL0zz6R2fza5mKYYF3",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/console_table.png`,
    "mobileImage": ""
  },
  {
    "productId": "reed_diffuser",
    "order": 21,
    "brandName": "Apotheke",
    "productNameComplete": "White Vetiver Reed Diffuser",
    "productNameAbbreviated": "Reed Diffuser",
    "pricingDiscountLine": "Save 40% off retail",
    "dollarLine": "$60",
    "strikeout": true,
    "variant": "N/A",
    "quantity": 1,
    "url": "https://apothekeco.com/products/white-vetiver-reed-diffuser?srsltid=AfmBOoqnzL18jyTTBxK20YRfF660AcrLgv1iEAFuF6hckIAz-ZHE8AoT",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/reed_diffuser.png`,
    "mobileImage": ""
  },
  {
    "productId": "desk_lamp",
    "order": 22,
    "brandName": "Wayfair",
    "productNameComplete": "Ulverst Adjustable Metal Desk Lamp",
    "productNameAbbreviated": "Desk Lamp",
    "pricingDiscountLine": "Save 10% off Pro price",
    "dollarLine": "$65",
    "strikeout": true,
    "variant": "N/A",
    "quantity": 1,
    "url": "https://www.wayfair.com/lighting/pdp/willa-arlo-interiors-ulverst-20-brass-desk-lamp-w006114710.html",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/desk_lamp.png`,
    "mobileImage": ""
  },
  {
    "productId": "writing_desk",
    "order": 23,
    "brandName": "Wayfair",
    "productNameComplete": "29.13\" H x 39.37\" W x 17.71\" D Djellona Metal Base Writing Desk",
    "productNameAbbreviated": "Writing Desk",
    "pricingDiscountLine": "Save 10% off Pro price",
    "dollarLine": "$70",
    "strikeout": true,
    "variant": "Oak Gray / Black",
    "quantity": 1,
    "url": "https://www.wayfair.com/furniture/pdp/17-stories-desk-w003744639.html?piid%5B0%5D=1553562121&piid%5B1%5D=1553562135",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/writing_desk.png`,
    "mobileImage": ""
  },
  {
    "productId": "dual_charging_stand",
    "order": 24,
    "brandName": "Courant",
    "productNameComplete": "Dual Device Charging Stand MAG:2 Classics",
    "productNameAbbreviated": "Dual Charging Stand",
    "pricingDiscountLine": "Save 30% off retail",
    "dollarLine": "$150",
    "strikeout": true,
    "variant": "Leather",
    "quantity": 1,
    "url": "https://staycourant.com/products/mag-2-classics?title=cortado",
    "imageLink": "",
    "desktopImage": `${baseImageUrl}/aug24/dual_charging_stand.png`,
    "mobileImage": ""
  }
]

const productSlideConfig = {
  autoplay:false,
  infinite: true,
  "draggable": false,
  "dots": false,
  "arrows": true,
  "prevArrow": "<button class='control-c prev slick-prev'><img class='' src='/assets/images/shop/left_arrow.svg'></button>",
  "nextArrow":"<button class=' control-c next slick-next'><img  src='/assets/images/shop/right_arrow.svg'></button>",
  "responsive": [
    {
      breakpoint: 80000,
      settings: {
        slidesToShow: 11,
        slidesToScroll:6,
        infinite: true,
        variableWidth: true,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 6,
        infinite: true,
        variableWidth: true,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
        infinite: false,
        variableWidth: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 6,
        infinite: true,
        variableWidth: true,
      }
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        variableWidth: true,
        swipeToSlide: true
      }
    }
  ]
};

export { productList, productSlideConfig }
