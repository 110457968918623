import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MultiselectListboxComponent} from "./multiselect-listbox/multiselect-listbox.component";
import {QtyInputComponent} from "./qty-input/qty-input.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {DirectivesModule} from "../directives/directives.module";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {ImageUploaderComponent} from './image-uploader/image-uploader.component';
import {ImageCropperModule} from "ngx-image-cropper";
import {SliderModule} from "primeng/slider";
import {DialogModule} from "primeng/dialog";
import {FileSelectorComponent} from './file-selector/file-selector.component';


@NgModule({
  declarations: [
    MultiselectListboxComponent,
    QtyInputComponent,
    ImageUploaderComponent,
    FileSelectorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RippleModule,
    ButtonModule,
    InputTextModule,
    DirectivesModule,
    ScrollingModule,
    ImageCropperModule,
    SliderModule,
    DialogModule,
  ],
    exports: [
        MultiselectListboxComponent,
        QtyInputComponent,
        ImageUploaderComponent,
        FileSelectorComponent,
    ]
})
export class FormElementsModule { }
