import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaxLengthDirective} from "./maxLengthDirective/max-length.directive";
import {OnlyDigitsDirective} from "./onlyDigitsDirective/only-digits.directive";
import {ToUpperCaseDirective} from "./toUpperCaseDirective/to-upper-case.directive";
import {DoubleClickDirective} from "./doubleClickDirective/double-click.directive";
import {TwoDecimalPlacesDirective} from './twoDecimalPlacesDirective/two-decimal-places.directive';
import {DragAndDropDirective} from './dragAndDropDirective/drag-and-drop.directive';
import {GoogleAutocompleteDirective} from "./googleAutocompleteDirective/google-autocomplete.directive";
import {PropertyNameDirective} from './proprtyNameDirective/property-name.directive';
import {AutoSizeTextInputDirective} from './autoSizeTextInputDirective/auto-size-text-input.directive';
import {ImgPlaceholderDirective} from './imgPlaceholderDirective/img-placeholder.directive';
import {NoWhiteSpaceNoNewLineDirective} from './noWhiteSpaceNoNewLine/no-white-space-no-new-line.directive';
import {WheelThrottleDirective} from './wheelThrottleDirective/wheel-throttle.directive';
import {IntersectionObserverDirective} from "./intersectionObserverDirective/intersection-observer.directive";


@NgModule({
  declarations: [
    MaxLengthDirective,
    OnlyDigitsDirective,
    ToUpperCaseDirective,
    DoubleClickDirective,
    TwoDecimalPlacesDirective,
    DragAndDropDirective,
    GoogleAutocompleteDirective,
    PropertyNameDirective,
    AutoSizeTextInputDirective,
    ImgPlaceholderDirective,
    NoWhiteSpaceNoNewLineDirective,
    WheelThrottleDirective,
    IntersectionObserverDirective
  ],
  imports: [
    CommonModule,
  ],
    exports: [
        MaxLengthDirective,
        OnlyDigitsDirective,
        ToUpperCaseDirective,
        DoubleClickDirective,
        TwoDecimalPlacesDirective,
        DragAndDropDirective,
        GoogleAutocompleteDirective,
        PropertyNameDirective,
        AutoSizeTextInputDirective,
        ImgPlaceholderDirective,
        NoWhiteSpaceNoNewLineDirective,
        WheelThrottleDirective,
      IntersectionObserverDirective
    ]
})
export class DirectivesModule { }
