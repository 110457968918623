<footer class="self_serve_footer">
  <div class="cstm_progress_bar" *ngIf="selfServeService.CURRENT_STEP !== 'getting-started'">
    <div class="progressbar progressbar_one progressbar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width:33.3%;">
      <div class="progressbar-inner" [ngStyle]="{'width': getFooterCompletion(0)}"></div>
    </div>
    <div class="progressbar progressbar_two progressbar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width:33.3%;">
      <div class="progressbar-inner" [ngStyle]="{'width': getFooterCompletion(1)}"></div>
    </div>
    <div class="progressbar progressbar_three progressbar-success" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width:33.3%;">
      <div class="progressbar-inner" [ngStyle]="{'width': getFooterCompletion(2)}"></div>
    </div>
<!--    <div class="progressbar progressbar_four" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width:25%;">-->
<!--      <div class="progressbar-inner" [ngStyle]="{'width': getFooterCompletion(3)}"></div>-->
<!--    </div>-->
  </div>
  <div class="container-fluid">
    <div class="inner_footer">
      <button  class="back_btn" [ngClass]="{'invisible': hideBackButton()}" (click)="backClick($event)">Back</button>
      <!-- appDoubleClick, [throttleTime]="1000" -->

      <button class="btn_step_text" *ngIf="selfServeService.CURRENT_STEP === 'spaces' && selfServeService.SPACE_PROGRESS === 3 && spaceCount && spaceCount.totalSpace > 0">
        <span class="sub_bold">{{spaceCount.spaceWithItems}}</span> of <span class="sub_bold">{{spaceCount.totalSpace}}</span> spaces with items
      </button>

      <button  appDoubleClick class="skip_btn" *ngIf="showSkipButton" (click)="onSkipButtonClick($event)">Skip</button>

      <button class="primary_btn new_start_btn" (click)="nextClick($event)" [disabled]="selfServeService.NEXT_BUTTON_DISABLE">
        <!-- <span  class="loader_icon"></span>, appDoubleClick [throttleTime]="1000" [isDisable]="true"-->
        <div class="loader_btn_ui" *ngIf="nextLoading"><span class="loader_icon"></span></div>
        {{selfServeService.wizardButtonName}}
        <img *ngIf="selfServeService.wizardButtonName !== 'Finish'" class="next_icon" src="assets/images/arrow.svg" alt="">
      </button>
    </div>
  </div>
</footer>

<!--Next Confirmation on showroom name change Modal -->
<p-dialog [(visible)]="nextConfirmation" [showHeader]="false" [modal]="true" [baseZIndex]="10000"
          [draggable]="false" [dismissableMask]="true" [resizable]="false" [closeOnEscape]="true"
          styleClass=" cart_modal modal_754_cart update_property_modal"  maskStyleClass="mask-class-2">
  <div class="modal-content ">
    <div class="modal-header">
      <div class="heading_wrap">
        <h2 class="heading3">Update property name everywhere?</h2>
      </div>
      <button (click)="nextConfirmation = false;" type="button" class="close-btn">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5 1.5L1.5 16.5M1.5 1.5L16.5 16.5" stroke="#96999F" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="modal-body">
      <div class="modal-body-description">
        <p class="text_des">
          <span>‘{{selfServeService.propertyData?.showroomName}}’</span> is the name used for managing your Quotes & Orders for this property. Would you like to update to <span>‘{{selfServeService.showroomSetup.showroomName}}’</span> across the full Minoan platform?
        </p>
      </div>
    </div>

      <div class="modal-footer align-self-center">
        <button type="button" id="syncNo" class=" btn btn_secondary" appDoubleClick (throttledClick)="updateShowroomInfo()">No, just the Showroom</button>
        <button type="button" id="syncYes" class="btn btn_primary" appDoubleClick (throttledClick)="updateShowroomInfo(true)">Yes, update everywhere</button>
      </div>

  </div>
</p-dialog>
<!--Next Confirmation on showroom name change Modal end -->
