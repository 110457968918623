import {Injectable} from '@angular/core';
import {AuthService} from "../services/auth/auth.service";
import {HttpHandler, HttpRequest} from "@angular/common/http";
import {exhaustMap, take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(public auth : AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return this.auth.user.pipe(
      take(1), exhaustMap(
        user => {
          const dt = new Date()
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          const tz = dt.getTimezoneOffset()

          if(!localStorage.getItem('utcOffset')){
            localStorage.setItem('utcOffset', JSON.stringify(tz))
          }

          if (user) {
            const modifiedRequest = req.clone({
              setHeaders: {
                Authorization: 'Bearer ' + user['token'],
                utcoffset: `${tz}`,
                timeZone: `${timeZone}`
              }
            });
            return next.handle(modifiedRequest);
          } else {
            const modifiedRequest = req.clone({
              setHeaders: {
                utcoffset: `${tz}`,
                timeZone: `${timeZone}`
              }
            });
            return next.handle(modifiedRequest);
          }
        }
      )
    );


  }
}
