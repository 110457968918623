import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {GettingStartedService} from "../../../../services/misc/getting-started.service";

@Component({
  selector: 'loom-video',
  templateUrl: './loom.component.html',
  styleUrls: ['./loom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoomComponent implements OnChanges {

  @Input() loomModal: boolean = false;
  @Output() loomModalChange = new EventEmitter<boolean>();

  @Input() ftuxDropdownAutoClose: boolean | 'inside' | 'outside' = true;
  @Output() ftuxDropdownAutoCloseChange: EventEmitter<boolean | 'inside' | 'outside'> = new EventEmitter<boolean | 'inside' | 'outside'>();


  @Input() loomContent: number = 0
  @Output() loomContentChange: EventEmitter<number> = new EventEmitter<number>()

  loomVideoEmbededLink: string = ''

  @Input() videoUrl: string = ''

  constructor(
    public domSanitizer: DomSanitizer,
    public gettingStartedService: GettingStartedService,
  ) {
  }


  ngOnChanges(changes: SimpleChanges) {
    if(changes['loomContent']) {
      const currentIndex = changes['loomContent'].currentValue
      this.loomVideoEmbededLink = this.gettingStartedService.GETTING_STARTED_CONTENT[currentIndex].embedLink;
    }
  }

  /**
   * Disable ftux dropdown close on click outside when Loom video is open
   */
  onShowLoomVideoModal() {
    this.ftuxDropdownAutoCloseChange.emit(false);
  }

  /**
   * Enable ftux dropdown close on click outside when Loom video is open
   */
  onHideLoomVideoModal() {
    this.loomModalChange.emit(false);
    this.ftuxDropdownAutoCloseChange.emit(true);
    this.loomContentChange.emit(0)
  }

}
