@for (message of toastService.toasts; track message) {
  <ngb-toast
    [class]="message.styleClass"
    [autohide]="true"
    [delay]="message.life || 5000"
    (hidden)="deleteToast(message)"
    >
    @if (!message.data && message.severity == 'success') {
      <div class="toast_content_wrapper">
        <i class="pi-check"></i>
        <p class="p-toast-detail">{{message.detail}}</p>
      </div>
    }
    @if (!message.data && message.severity == 'error') {
      <div class="toast_content_wrapper">
        <i class="pi-times-circle"></i>
        <p class="p-toast-detail">{{message.detail}}</p>
      </div>
    }
    <!--    Move to toast-->
    @if (message.data) {
      <div class="toast_content_wrapper" style="flex: 1">
        @if (message.data.requestType == 'move') {
          <i class="pi-check"></i>
          @if (message.data.itemListLength == 1) {
            <p class="p-toast-detail">
              Item moved to
              <a routerLink="{{message.data.href}}">
                @if (message.data.moveToLocation != 'Quote') {
                  {{message.data.moveToLocation}}
                }
                @if (message.data.moveToLocation == 'Quote') {
                  {{message.data.quoteName}}
                }
              </a>.
            </p>
          }
          @if (message.data.itemListLength > 1) {
            <p class="p-toast-detail">{{message.data.itemListLength}} items moved to
              <a routerLink="{{message.data.href}}">
                @if (message.data.moveToLocation != 'Quote') {
                  {{message.data.moveToLocation}}
                }
                @if (message.data.moveToLocation == 'Quote') {
                  {{message.data.quoteName}}
                }
              </a>.
            </p>
          }
        }
        @if (message.data.requestType == 'add') {
          <i class="pi-check"></i>
          @if (message.data.itemListLength == 1) {
            <p class="p-toast-detail">
              Item added to
              <a routerLink="{{message.data.href}}">
                @if (message.data.moveToLocation != 'Quote') {
                  {{message.data.moveToLocation}}
                }
                @if (message.data.moveToLocation == 'Quote') {
                  {{message.data.quoteName}}
                }
              </a>.
            </p>
          }
          @if (message.data.itemListLength > 1) {
            <p class="p-toast-detail">{{message.data.itemListLength}} items added to
              <a routerLink="{{message.data.href}}">
                @if (message.data.moveToLocation != 'Quote') {
                  {{message.data.moveToLocation}}
                }
                @if (message.data.moveToLocation == 'Quote') {
                  {{message.data.quoteName}}
                }
              </a>.
            </p>
          }
        }
      </div>
    }
  </ngb-toast>
}
