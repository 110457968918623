import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, map, take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    public http: HttpClient
  ) { }


  /**
   * [POST] API to validate account password create and reset key
   * @param body Request body
   */
  validateResetKey(body: any){
    return this.http.post(`${environment.apiUrl}/validate/reset-key`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}));
  }

  /**
   * [PUT] API to change account password
   * @param body Request body
   */
  createPassword(body: any) {
    return this.http.put(`${environment.apiUrl}/resetPasswordFromLink`,body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}));
  }

  /**
   * [PUT] API to request forgot password email
   * @param body Request body
   */
  forgotPassword(body: any) {
    return this.http.put(`${environment.apiUrl}/forgotPassword`,body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}));
  }

  /**
   * [POST] API to validate account setup key for new user
   * @param body Request body
   */
  validateSetupKeyNewUser(body: any){
    return this.http.post(`${environment.apiUrl}/new/account/invite/key/verify`,body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}));
  }

  /**
   * [PUT] API to submit setup account form
   * @param body Request body
   */
  setUpAccount(body: any){
    return this.http.put(`${environment.apiUrl}/account/setup`,body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}));
  }

  /**
   * [POST] API to validate account key for old user
   * @param body Request body
   */
  validateSetupKeyOldUser(body: any){
    return this.http.post(`${environment.apiUrl}/invite/key/verify`,body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}));
  }
}
