import {Injectable} from '@angular/core';
import {BehaviorSubject, map} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {StatsType} from "../../interfaces/stats.type";

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  // To store Global Stats
  globalStats = new BehaviorSubject<StatsType>({
    cartQuantity: 0,
    availableCredits: 0,
    refundCredit: 0,
    wishlistQuantity: 0,
    bonusCredit: 0
  });

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Update global count variable.
   */
  updateGlobalStats() {
    this.http.get(`${environment.apiUrl}/shopping/stats`).pipe(map((response: any) => response.data)).subscribe({
      next: (response: StatsType) => {
        this.globalStats.next(response);
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

}
