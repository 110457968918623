<div class="loader_wrapper">
  <div class="loader_positioning">
    <div class="loader2" *ngIf="isLoading"></div>
    <div class="text_loader" *ngIf="isLoading">File is downloading, please wait.</div>
    <div class="text_loader" *ngIf="!isLoading">
      <div>
        File downloaded successfully.
      </div>
      <div>
          Unable to locate file? <a href="javascript:void(0)" (click)="downloadFileNow()">Click here to download again</a>
      </div>
    </div>
  </div>
</div>
