import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShowroomOrderEntryComponent} from "./showroom-order-entry/showroom-order-entry.component";
import {LoaderComponent} from "./loader/loader.component";
import {ToastComponent} from "./toast/toast.component";
import {InlineLoaderComponent} from "./inline-loader/inline-loader.component";
import {NgbToastModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    LoaderComponent,
    ToastComponent,
    InlineLoaderComponent,
    ShowroomOrderEntryComponent
  ],
  imports: [
    CommonModule,
    NgbToastModule,
    RouterModule,
  ],
  exports: [
    LoaderComponent,
    ToastComponent,
    InlineLoaderComponent,
    ShowroomOrderEntryComponent
  ]
})
export class SharedComponentsModule { }
