<span [class]="styleClass" [ngClass]="{'p-inputnumber p-component': true, 'p-inputnumber-buttons-horizontal': true }">
  <button (mouseup)="decrease()" (keyup)="handleKeyUp($event, 'decrease')" [ngClass]="{'p-inputnumber-button p-inputnumber-button-down': true}" [disabled]="disabled" [class]="decrementButtonClass" [icon]="decrementButtonIcon" pButton
          type="button"></button>
  <input #input [attr.aria-required]="ariaRequired" [attr.autocomplete]="autocomplete" [attr.id]="inputId" [attr.max]="max"
         [attr.maxlength]="maxlength" [attr.min]="min"
         [attr.name]="name" [attr.placeholder]="placeholder" [attr.required]="required" [attr.size]="size"
         [attr.tabindex]="tabindex" [attr.title]="title" [class]="inputStyleClass" [ngStyle]="inputStyle"
         [value]="value" [disabled]="disabled" (focusin)="onFocus($event)" (blur)="onInputBlur($event)" (keydown)="onInputKeyDown($event)" inputMode="numeric" [ngClass]="'p-inputnumber-input'" appOnlyDigits pInputText
  >
  <button (mouseup)="increase()" (keyup)="handleKeyUp($event, 'add')" [ngClass]="{'p-inputnumber-button p-inputnumber-button-up': true}" [disabled]="disabled" [class]="incrementButtonClass" [icon]="incrementButtonIcon" pButton
          type="button"></button>
</span>
