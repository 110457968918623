import {Injectable, signal, WritableSignal} from '@angular/core';
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {Subscription} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  showMobileDevice: WritableSignal<boolean> = signal(false)
  mobileDeviceObserver!: Subscription;
  constructor(public breakpointObserver: BreakpointObserver,) { }

  startObserver() {
    this.mobileDeviceObserver = this.breakpointObserver.observe("(max-width: 768px)").subscribe({
      next: (value: BreakpointState) => {
        this.showMobileDevice.set(value.matches)
      }
    })
  }
}
