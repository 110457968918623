import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, map, take} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {isEqual} from "lodash-es";

export interface accessObject {
  // Accounts Page
  [accountInfoEditAccess: string]: boolean,

  myInfoEditAccess: boolean,

  // Properties Page
  addPropertyAccess: boolean,
  editPropertyAccess: boolean,
  deletePropertyAccess: boolean,

  // Users Page
  inviteNewUserAccess: boolean,
  resendInviteAccess: boolean,
  deleteInviteAccess: boolean,
  editMemberOrAdminAccess: boolean,
  editOwnerAccess: boolean,
  deleteMemberOrAdminAccess: boolean,
  isMember: boolean;
}

export interface CompanyInformation {
  companyId: number;
  companyName: string;
}

@Injectable({
  providedIn: 'root'
})
export class PropertySelectionService {


  // Currently selected Account
  selectedCompanyInformation = new BehaviorSubject<CompanyInformation>({
    companyId: 0,
    companyName: ''
  });

  // List of available accounts
  availableCompanyList = new BehaviorSubject<CompanyInformation[]>([])

  // Access object for current user based on user role
  accessObjectBehaviorSubject = new BehaviorSubject<accessObject>({
    // Accounts Page
    accountInfoEditAccess: false,
    myInfoEditAccess: false,

    // Properties Page
    addPropertyAccess: false,
    editPropertyAccess: false,
    deletePropertyAccess: false,


    // Users Page
    inviteNewUserAccess: false,
    resendInviteAccess: false,
    deleteInviteAccess: false,
    editMemberOrAdminAccess: false,
    editOwnerAccess: false,
    deleteMemberOrAdminAccess: false,
    isMember: false,

    // Addresses Page
    addNewAddressAccess: false,
    editAddressAccess: false,
    changeDefaultAddressAccess: false,
  })


  constructor(
    public http: HttpClient
  ) {
  }


  /**
   * API Call : To Set Active company and property
   * Set Active company & property locally.
   * @param data
   */
  setActiveCompanyAndProperty(data: any) {
    return this.http.post(`${environment.apiUrl}/active/property/company`, data)
      .pipe(take(1), map((response: any) => response), catchError((err: any) => {
        throw err
      }));
  }


  /**
   * Set Current Company Information in Behaviour Subject
   * @param companyData Company Data
   */
  set currentCompany(companyData: CompanyInformation) {
    if (!isEqual(companyData, this.selectedCompanyInformation.getValue())) {
      this.selectedCompanyInformation.next(companyData)
    }
  }

  /**
   * Set available company list data
   * @param companyList Company List
   */
  set availableCompanyListData(companyList: CompanyInformation[]) {
    if (!isEqual(companyList, this.availableCompanyList.getValue())) {
      this.availableCompanyList.next(companyList);
    }
  }

  /**
   * Set data for selected company, property, property list and company list. Also create access object for the user
   * @param selectedCompany Selected company (with property list)
   * @param companyList List of available companies
   * @param isMasterAdmin Whether user is master admin or not
   */
  setAllData(selectedCompany: any, companyList: any[] = [], isMasterAdmin: boolean = false) {
    this.availableCompanyListData = companyList.map(each => ({
      companyName: each.companyName,
      companyId: each.companyId
    }))
    this.currentCompany = {
      companyId: selectedCompany.companyId!,
      companyName: selectedCompany.companyName!
    }

    let access = this.accessObjectBehaviorSubject.getValue();
    const companyId = this.selectedCompanyInformation.getValue().companyId;
    const account = isMasterAdmin ? selectedCompany : companyList?.find((eachAccount: any) => eachAccount.companyId == companyId)!;

    if(isMasterAdmin) {
      account.roleType = 'admin';
    }

    // Reset access, then create new access object
    Object.keys(access).forEach(eachAccess => access[eachAccess] = false);
    if (account.roleType == 'owner') {
      // OWNER
      Object.keys(access).forEach(eachAccess => access[eachAccess] = true);
      access['isMember'] = false;
    } else if (account.roleType == 'admin') {
      // ADMIN
      Object.keys(access).forEach(eachAccess => access[eachAccess] = true);
      access['accountInfoEditAccess'] = false;
      access['editOwnerAccess'] = false;
      access['isMember'] = false;
    } else {
      // MEMBER
      access['inviteNewUserAccess'] = true;
      access['myInfoEditAccess'] = true;
      access['isMember'] = true;
      // access['addPropertyAccess'] = true;
      access['editPropertyAccess'] = true;
    }

    this.accessObjectBehaviorSubject.next(access)
  }

}
