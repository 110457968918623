import {Directive, ElementRef, forwardRef, HostListener, Input, Renderer2} from '@angular/core';
import {DefaultValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

/**
 * Directive that only allows you to enter digits in a given field
 * @param maxLength (optional) To add max length limitation to the field
 */
@Directive({
  selector: '[appOnlyDigits]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => OnlyDigitsDirective),
  }]
})
export class OnlyDigitsDirective extends DefaultValueAccessor  {

  // To limit max length of the input
  @Input() maxLength: number | undefined = undefined;

  @HostListener('input', ['$event']) input($event: InputEvent) {
    const target = $event.target as HTMLInputElement;
    const start = target.selectionStart;

    if(this.maxLength){
      target.value = target.value.slice(0, this.maxLength).replace(/\D/, '');
    } else {
      target.value = target.value.replace(/\D/, '');
    }
    target.setSelectionRange(start, start);

    this.onChange(target.value);
  }

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }


}
