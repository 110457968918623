import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ObjectUtils} from "primeng/utils";
import {FilterService, Footer, Header, PrimeNGConfig, PrimeTemplate, TranslationKeys} from "primeng/api";
import {DomHandler} from "primeng/dom";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Subscription} from "rxjs";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";

export const LISTBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectListboxComponent),
  multi: true
};

@Component({
  selector: 'app-multiselect-listbox',
  templateUrl: './multiselect-listbox.component.html',
  styleUrls: ['./multiselect-listbox.component.scss'],
  providers: [LISTBOX_VALUE_ACCESSOR]
})
export class MultiselectListboxComponent implements AfterContentInit, OnInit, ControlValueAccessor, OnDestroy, OnChanges {

  @Input() multiple: boolean = false;
  @Input() style: any;
  @Input() styleClass: string = '';
  @Input() listStyle: any;
  @Input() listStyleClass: string = '';
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() checkbox: boolean;
  @Input() filter: boolean;
  @Input() filterBy: string = '';
  @Input() filterMatchMode: string;
  @Input() filterLocale: string = '';
  @Input() metaKeySelection: boolean;
  @Input() dataKey: string = '';
  @Input() showToggleAll: boolean;
  @Input() optionLabel: string = '';
  @Input() optionValue: string = '';
  @Input() optionGroupChildren: string;
  @Input() optionGroupLabel: string = '';
  @Input() optionDisabled: string = '';
  @Input() ariaFilterLabel: string = '';
  @Input() filterPlaceHolder: string = '';
  @Input() emptyFilterMessage: string = '';
  @Input() emptyMessage: string = '';
  @Input() group: boolean = false;
  @Input() type: string = '';


  onChange: EventEmitter<any>;
  onClick: EventEmitter<any>;
  onDblClick: EventEmitter<any>;


  @ViewChild('headerchkbox') headerCheckboxViewChild: ElementRef | undefined;
  @ViewChild('filter') filterViewChild: ElementRef | undefined;
  @ContentChild(Header) headerFacet: any;
  @ContentChild(Footer) footerFacet: any;
  @ContentChildren(PrimeTemplate) templates: QueryList<any> | undefined;
  itemTemplate: TemplateRef<any> | any;
  groupTemplate: TemplateRef<any> | any;
  headerTemplate: TemplateRef<any> | any;
  filterTemplate: TemplateRef<any> | any;
  footerTemplate: TemplateRef<any> | any;
  emptyFilterTemplate: TemplateRef<any> | any;
  emptyTemplate: TemplateRef<any> | any;
  _filteredOptions: any;
  filterOptions: any | undefined;
  filtered: boolean = false;
  value: any;
  onModelChange: Function;
  onModelTouched: Function;
  optionTouched: boolean = false;
  focus: boolean = false;
  headerCheckboxFocus: boolean = false;
  translationSubscription: Subscription | undefined;

  @Input() dropdownClose: boolean = false;
  @Output() dropdownCloseChange: EventEmitter<any> = new EventEmitter<any>()

  @Input() virtualScroll: boolean = false;
  @Input() itemSize: number = 25;
  @ViewChild('itemListScroller') itemListScroller: CdkVirtualScrollViewport | undefined;

  constructor(
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    public filterService: FilterService,
    public config: PrimeNGConfig
  ) {
    this.el = el;
    this.cd = cd;
    this.filterService = filterService;
    this.config = config;
    this.checkbox = false;
    this.filter = false;
    this.filterMatchMode = 'contains';
    this.metaKeySelection = true;
    this.showToggleAll = true;
    this.optionGroupChildren = 'items';
    this.onChange = new EventEmitter();
    this.onClick = new EventEmitter();
    this.onDblClick = new EventEmitter();
    this.onModelChange = () => {
    };
    this.onModelTouched = () => {
    };
  }

  _options: any;

  get options() {
    return this._options;
  }

  @Input() set options(val) {
    this._options = val;
    if (this.hasFilter())
      this.activateFilter();
  }

  _filterValue: string | null = '';

  get filterValue() {
    return this._filterValue;
  }

  @Input() set filterValue(val) {
    this._filterValue = val;
    this.activateFilter();
  }

  get allChecked() {
    let optionsToRender = this.optionsToRender;
    if (!optionsToRender || optionsToRender.length === 0) {
      return false;
    } else {
      let selectedDisabledItemsLength = 0;
      let unselectedDisabledItemsLength = 0;
      let selectedEnabledItemsLength = 0;
      let visibleOptionsLength = this.group ? 0 : this.optionsToRender.length;
      for (let option of optionsToRender) {
        if (!this.group) {
          let disabled = this.isOptionDisabled(option);
          let selected = this.isSelected(option);
          if (disabled) {
            if (selected)
              selectedDisabledItemsLength++;
            else
              unselectedDisabledItemsLength++;
          } else {
            if (selected)
              selectedEnabledItemsLength++;
            else
              return false;
          }
        } else {
          for (let opt of this.getOptionGroupChildren(option)) {
            let disabled = this.isOptionDisabled(opt);
            let selected = this.isSelected(opt);
            if (disabled) {
              if (selected)
                selectedDisabledItemsLength++;
              else
                unselectedDisabledItemsLength++;
            } else {
              if (selected)
                selectedEnabledItemsLength++;
              else {
                return false;
              }
            }
            visibleOptionsLength++;
          }
        }
      }
      return (visibleOptionsLength === selectedDisabledItemsLength ||
        visibleOptionsLength === selectedEnabledItemsLength ||
        (selectedEnabledItemsLength && visibleOptionsLength === selectedEnabledItemsLength + unselectedDisabledItemsLength + selectedDisabledItemsLength));
    }
  }

  get optionsToRender() {
    return this._filteredOptions || this.options;
  }

  get emptyMessageLabel() {
    return this.emptyMessage || this.config.getTranslation(TranslationKeys.EMPTY_MESSAGE);
  }

  get emptyFilterMessageLabel() {
    return this.emptyFilterMessage || this.config.getTranslation(TranslationKeys.EMPTY_FILTER_MESSAGE);
  }

  get toggleAllDisabled() {
    let optionsToRender = this.optionsToRender;
    if (!optionsToRender || optionsToRender.length === 0) {
      return true;
    } else {
      for (let option of optionsToRender) {
        if (!this.isOptionDisabled(option))
          return false;
      }
      return true;
    }
  }

  ngOnInit() {
    this.translationSubscription = this.config.translationObserver.subscribe(() => {
      this.cd.markForCheck();
    });
    if (this.filterBy) {
      this.filterOptions = {
        filter: (value: any) => this.onFilter(value),
        reset: () => this.resetFilter()
      };
    }
  }

  ngAfterContentInit() {
    this.templates?.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'group':
          this.groupTemplate = item.template;
          break;
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'filter':
          this.filterTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        case 'empty':
          this.emptyTemplate = item.template;
          break;
        case 'emptyfilter':
          this.emptyFilterTemplate = item.template;
          break;
        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  getOptionLabel(option: any) {
    return this.optionLabel ? ObjectUtils.resolveFieldData(option, this.optionLabel) : option.label != undefined ? option.label : option;
  }

  getOptionGroupChildren(optionGroup: any) {
    return this.optionGroupChildren ? ObjectUtils.resolveFieldData(optionGroup, this.optionGroupChildren) : optionGroup.items;
  }

  getOptionGroupLabel(optionGroup: any) {
    return this.optionGroupLabel ? ObjectUtils.resolveFieldData(optionGroup, this.optionGroupLabel) : optionGroup.label != undefined ? optionGroup.label : optionGroup;
  }

  getOptionValue(option: any) {
    return this.optionValue ? ObjectUtils.resolveFieldData(option, this.optionValue) : this.optionLabel || option.value === undefined ? option : option.value;
  }

  isOptionDisabled(option: any) {
    return this.optionDisabled ? ObjectUtils.resolveFieldData(option, this.optionDisabled) : option.disabled !== undefined ? option.disabled : false;
  }

  writeValue(value: any) {
    this.value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: any) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onModelTouched = fn;
  }

  setDisabledState(val: any) {
    this.disabled = val;
    this.cd.markForCheck();
  }

  onOptionClick(event: any, option: any) {
    if (this.disabled || this.isOptionDisabled(option) || this.readonly) {
      return;
    }
    if (this.multiple) {
      if (this.checkbox)
        this.onOptionClickCheckbox(event, option);
      else
        this.onOptionClickMultiple(event, option);
    } else {
      this.onOptionClickSingle(event, option);
    }
    this.onClick.emit({
      originalEvent: event,
      option: option,
      value: this.value
    });
    this.optionTouched = false;
  }

  onOptionTouchEnd(option: any) {
    if (this.disabled || this.isOptionDisabled(option) || this.readonly) {
      return;
    }
    this.optionTouched = true;
  }

  onOptionDoubleClick(event: any, option: any) {
    if (this.disabled || this.isOptionDisabled(option) || this.readonly) {
      return;
    }
    this.onDblClick.emit({
      originalEvent: event,
      option: option,
      value: this.value
    });
  }

  onOptionClickSingle(event: any, option: any) {
    let selected = this.isSelected(option);
    let valueChanged = false;
    let metaSelection = this.optionTouched ? false : this.metaKeySelection;
    if (metaSelection) {
      let metaKey = event.metaKey || event.ctrlKey;
      if (selected) {
        if (metaKey) {
          this.value = null;
          valueChanged = true;
        }
      } else {
        this.value = this.getOptionValue(option);
        valueChanged = true;
      }
    } else {
      this.value = selected ? null : this.getOptionValue(option);
      valueChanged = true;
    }
    if (valueChanged) {
      this.onModelChange(this.value);
      this.onChange.emit({
        originalEvent: event,
        value: this.value
      });
    }
  }

  onOptionClickMultiple(event: any, option: any) {
    let selected = this.isSelected(option);
    let valueChanged = false;
    let metaSelection = this.optionTouched ? false : this.metaKeySelection;
    if (metaSelection) {
      let metaKey = event.metaKey || event.ctrlKey;
      if (selected) {
        if (metaKey) {
          this.removeOption(option);
        } else {
          this.value = [this.getOptionValue(option)];
        }
        valueChanged = true;
      } else {
        this.value = metaKey ? this.value || [] : [];
        this.value = [...this.value, this.getOptionValue(option)];
        valueChanged = true;
      }
    } else {
      if (selected) {
        this.removeOption(option);
      } else {
        this.value = [...(this.value || []), this.getOptionValue(option)];
      }
      valueChanged = true;
    }
    if (valueChanged) {
      this.onModelChange(this.value);
      this.onChange.emit({
        originalEvent: event,
        value: this.value
      });
    }
  }

  onOptionClickCheckbox(event: any, option: any) {
    if (this.disabled || this.readonly) {
      return;
    }
    let selected = this.isSelected(option);
    if (selected) {
      this.removeOption(option);
    } else {
      this.value = this.value ? this.value : [];
      this.value = [...this.value, this.getOptionValue(option)];
    }
    this.onModelChange(this.value);
    this.onChange.emit({
      originalEvent: event,
      value: this.value
    });
  }

  removeOption(option: any) {
    this.value = this.value.filter((val: any) => !ObjectUtils.equals(val, this.getOptionValue(option), this.dataKey));
  }

  isSelected(option: any) {
    let selected = false;
    let optionValue = this.getOptionValue(option);
    if (this.multiple) {
      if (this.value) {
        for (let val of this.value) {
          if (ObjectUtils.equals(val, optionValue, this.dataKey)) {
            selected = true;
            break;
          }
        }
      }
    } else {
      selected = ObjectUtils.equals(this.value, optionValue, this.dataKey);
    }
    return selected;
  }

  hasFilter() {
    return this._filterValue && this._filterValue.trim().length > 0;
  }

  isEmpty() {
    return !this.optionsToRender || (this.optionsToRender && this.optionsToRender.length === 0);
  }

  onFilter(event: any) {
    this._filterValue = event.target.value;
    this.activateFilter();
  }

  activateFilter() {
    if (this.hasFilter() && this._options) {
      if (this.group) {
        let searchFields = (this.filterBy || this.optionLabel || 'label').split(',');
        let filteredGroups = [];
        for (let optgroup of this.options) {
          let filteredSubOptions = this.filterService.filter(this.getOptionGroupChildren(optgroup), searchFields, this.filterValue, this.filterMatchMode, this.filterLocale);
          if (filteredSubOptions && filteredSubOptions.length) {
            filteredGroups.push({...optgroup, ...{[this.optionGroupChildren]: filteredSubOptions}});
          }
        }
        this._filteredOptions = filteredGroups;
      } else {
        this._filteredOptions = this._options.filter((option: any) => this.getOptionLabelNew(option).toLowerCase().includes(this._filterValue?.toLowerCase()))
      }
      this.itemListScroller?.scrollToIndex(0)
    } else {
      this._filteredOptions = null;
    }
  }

  getOptionLabelNew(option: any){
    if(this.type == 'user'){
      return `${option['firstName']} ${option['lastName']}`
    }
    return option[this.optionLabel]
  }

  resetFilter() {
    if (this.filterViewChild && this.filterViewChild.nativeElement) {
      this.filterViewChild.nativeElement.value = '';
    }
    this._filterValue = null;
    this._filteredOptions = null;
  }

  toggleAll(event: any) {
    if (this.disabled || this.toggleAllDisabled || this.readonly) {
      return;
    }
    let allChecked = this.allChecked;
    if (allChecked)
      this.uncheckAll();
    else
      this.checkAll();
    this.onModelChange(this.value);
    this.onChange.emit({originalEvent: event, value: this.value});
    event.preventDefault();
  }

  checkAll() {
    let optionsToRender = this.optionsToRender;
    let val: any = [];
    optionsToRender.forEach((opt: any) => {
      if (!this.group) {
        let optionDisabled = this.isOptionDisabled(opt);
        if (!optionDisabled || (optionDisabled && this.isSelected(opt))) {
          val.push(this.getOptionValue(opt));
        }
      } else {
        let subOptions = this.getOptionGroupChildren(opt);
        if (subOptions) {
          subOptions.forEach((option: any) => {
            let optionDisabled = this.isOptionDisabled(option);
            if (!optionDisabled || (optionDisabled && this.isSelected(option))) {
              val.push(this.getOptionValue(option));
            }
          });
        }
      }
    });
    this.value = val;
  }

  uncheckAll() {
    let optionsToRender = this.optionsToRender;
    let val: any = [];
    optionsToRender.forEach((opt: any) => {
      if (!this.group) {
        let optionDisabled = this.isOptionDisabled(opt);
        if (optionDisabled && this.isSelected(opt)) {
          val.push(this.getOptionValue(opt));
        }
      } else {
        if (opt.items) {
          opt.items.forEach((option: any) => {
            let optionDisabled = this.isOptionDisabled(option);
            if (optionDisabled && this.isSelected(option)) {
              val.push(this.getOptionValue(option));
            }
          });
        }
      }
    });
    this.value = val;
  }

  onOptionKeyDown(event: any, option: any) {
    if (this.readonly) {
      return;
    }
    let item = event.currentTarget;
    switch (event.which) {
      //down
      case 40:
        var nextItem = this.findNextItem(item);
        if (nextItem) {
          nextItem.focus();
        }
        event.preventDefault();
        break;
      //up
      case 38:
        var prevItem = this.findPrevItem(item);
        if (prevItem) {
          prevItem.focus();
        }
        event.preventDefault();
        break;
      //enter
      case 13:
        this.onOptionClick(event, option);
        event.preventDefault();
        break;
    }
  }

  findNextItem(item: any): any {
    let nextItem = item.nextElementSibling;
    if (nextItem)
      return DomHandler.hasClass(nextItem, 'p-disabled') || DomHandler.isHidden(nextItem) || DomHandler.hasClass(nextItem, 'p-listbox-item-group') ? this.findNextItem(nextItem) : nextItem;
    else
      return null;
  }

  findPrevItem(item: any): any {
    let prevItem = item.previousElementSibling;
    if (prevItem)
      return DomHandler.hasClass(prevItem, 'p-disabled') || DomHandler.isHidden(prevItem) || DomHandler.hasClass(prevItem, 'p-listbox-item-group') ? this.findPrevItem(prevItem) : prevItem;
    else
      return null;
  }

  onHeaderCheckboxFocus() {
    this.headerCheckboxFocus = true;
  }

  onHeaderCheckboxBlur() {
    this.headerCheckboxFocus = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dropdownClose']){
      // const change = changes['dropdownClose']
      const event = {
        target: {
          value: ''
        }
      }
      this.onFilter(event)
    }
  }

  clearFilter() {
    if(this.filterValue != ''){
      const event = {
        target: {
          value: ''
        }
      }
      this.onFilter(event)
    }
  }

  ngOnDestroy() {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }
}
