import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {BehaviorSubject, catchError, firstValueFrom, map, take} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {SpaceInformationType} from "../../interfaces/spaceInformation.type";
import {flatten, fromPairs, map as lmap, sortBy} from "lodash-es";

@Injectable({
  providedIn: 'root'
})
export class SpacesService {

  constructor(
    public http: HttpClient,
  ) { }

  SPACE_LIST = new BehaviorSubject<SpaceInformationType[]>([])

  SPACE_CREATION_FORM = new BehaviorSubject<any>({})
  SPACE_IMAGES_FORM = new BehaviorSubject<any>(undefined)
  SPACE_WITH_ITEMS = new BehaviorSubject<{totalSpace: number, spaceWithItems: number} | undefined>(undefined);


  /**
   * [GET] API to fetch list of spaces for a property
   * @param requestBody Request body
   * */
  getListOfSpaces(requestBody: {propertyId: number}) {
    return this.http.get(`${environment.apiUrl}/space/list?propertyId=${requestBody.propertyId}`).pipe(
      take(1), map((response: any) => {

        const {spaceList} = response.data
        this.SPACE_LIST.next(spaceList);
        const count = spaceList.reduce((num: number, space: any) => {
          return Number(space.productCount) > 0 ? num + 1 : num;
        }, 0);
        this.SPACE_WITH_ITEMS.next({totalSpace: spaceList.length, spaceWithItems: count});
        return response.data
      }), catchError(err => {throw err})
    )
  }

  /**
   * [POST] API to create a new space
   * @param requestBody Request body
   */
  createASpace(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/space/create`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * [Put] API to edit a space
   * @param requestBody Request body
   */
  editASpace(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/space/edit`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * [POST] API to creat a duplicate space
   * @param requestBody Request body
   */
  duplicateASpace(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/space/duplicate`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * [DELETE] API to remove a space
   * @param requestBody Request body
   */
  deleteSpace(requestBody: { propertyId: number, spaceId: number }) {
    return this.http.delete(`${environment.apiUrl}/space/delete?spaceId=${requestBody.spaceId}&propertyId=${requestBody.propertyId}`).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * [PUT] API to reorder spaces
   * @param requestBody Request body
   */
  reorderSpaceList(requestBody: {propertyId: number, subspaceList: { id: number, orderNumber: number}[] }) {
    return this.http.put(`${environment.apiUrl}/space/reorder`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {throw err})
    )
  }

  /**
   * To update list of spaces in service
   * @param propertyId
   */
  async updateSpaceList(propertyId: number) {
    await firstValueFrom(this.getListOfSpaces({propertyId}))
  }

  /**
   * [POST] API to setup multiple spaces
   * @param requestBody
   */
  createSpaces(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/space/setup`, requestBody).pipe(
	    take(1), map((response: any) => response.data), catchError(err => {
		    throw err
	    })
    )
  }

  /**
   * [POST] API to save spaces image
   * @param requestBody
   */
  saveSpacesImage(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/update/space/image`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    )
  }

  /**
   * To update step of space form
   */
  async updateSpaceStep(requestBody: { propertyId: number, spaceSetupStep: number }) {
    return firstValueFrom(this.updateSpaceStepAPI(requestBody)).catch(err => {
      console.log(err)
    })
  }

  /**
   * [PUT] API to update step of space setup
   * @param requestBody
   */
  updateSpaceStepAPI(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/space/setup/step/update`, requestBody).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    )
  }

  get spaceList(): SpaceInformationType[] {
    return this.SPACE_LIST.getValue();
  }

  /**
   * Set Space list to for create spaces
   * @param spaceInformationForm
   */
  prepareSpaceList(spaceInformationForm: any) {
    const spaceList: { title: string, spaceCategory: string }[][] = []
    const suggestedSpaces = spaceInformationForm['suggestedSpaces']
    for (const each of Object.keys(spaceInformationForm)) {
      if (!['customSpaces', 'suggestedSpaces'].includes(each)) {
        // Sort each internally and push into space list
        const currentSpace = suggestedSpaces.find((x: { type: string }) => x.type === each)
        const index = fromPairs(lmap(currentSpace.spaces, (x, i) => [x, i]));
        const sortedSpaces = sortBy(spaceInformationForm[each], x => index[x]).map(each => ({
          title: each, spaceCategory: currentSpace.type
        }))
        spaceList.push(sortedSpaces)
      }
    }
    return flatten(spaceList)
  }

}
