

<ng-container *ngIf="toolTipType === 'paid' || toolTipType === 'ach-initiated'; else notPaid">
  <p class="tooltip_credit_text check_credit_text">
    <span>{{credit}}</span> was applied to this order.
  </p>
  <ng-container *ngFor="let elem of creditItems">
    <p class="tooltip_credit_text check_credit_text"><span>{{elem.text}}:</span> {{elem.value}}</p>
  </ng-container>
</ng-container>

<ng-template #notPaid>
  <ng-container>
    <p class="tooltip_credit_text check_credit_text">
      You have a <span>{{credit}}</span> {{message}}
    </p>
    <ng-container *ngFor="let elem of creditItems">
      <p class="tooltip_credit_text check_credit_text"><span>{{elem.text}}:</span> {{elem.value}}</p>
    </ng-container>
  </ng-container>
</ng-template>


