import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

const INPUTNUMBER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => QtyInputComponent),
  multi: true
}


@Component({
  selector: 'app-qty-input',
  templateUrl: './qty-input.component.html',
  styleUrls: ['./qty-input.component.scss'],
  providers: [INPUTNUMBER_VALUE_ACCESSOR],
})
export class QtyInputComponent implements OnInit, ControlValueAccessor {

  @Input() ariaRequired: string = ''
  @Input() autocomplete: string = ''
  @Input() inputId: string = ''
  @Input() maxlength: string = ''
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() required: string = ''
  @Input() size: string = ''
  @Input() tabindex: string = '0'
  @Input() title: string = ''
  @Input() inputStyleClass: string = ''
  @Input() styleClass: string = ''
  @Input() inputStyle: any;
  @Input() incrementButtonClass: string = '';
  @Input() decrementButtonClass: string = '';
  @Input() incrementButtonIcon: string = '';
  @Input() decrementButtonIcon: string = '';

  value: number = 0;
  onChange: any = () => { };
  onTouched: any = () => { };

  @Input() min: number = 1;
  @Input() max: number = 0;
  disabled = false;

  @ViewChild('input') input: ElementRef | undefined;

  @Output() onDeleteButtonPress: EventEmitter<any> = new EventEmitter<any>()

  @Output() enterKeyPressed: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {

  }

  increase() {
    this.value = Math.ceil(this.value + 1)
    this.onChange(this.value)
  }

  decrease() {
    const value = Math.ceil(this.value - 1);
    if(value == 0){
      this.onDeleteButtonPress.emit();
    } else {
      if(value < this.min){
        this.value = this.min;
      } else {
        this.value = value;
      }
      this.onChange(this.value);
    }

  }

  onInputBlur(event: any){
    const value = this.input?.nativeElement.value;
    if(value == ''){
      this.value = this.min;
      this.input!.nativeElement.value = this.min
      this.onChange(this.value);
    } else {
      if(parseInt(value) !== this.value){
        if(parseInt(value) < this.min){
          this.value = this.min;
          this.input!.nativeElement.value = this.min
        } else {
          this.value = parseInt(value);
        }
        this.onChange(this.value);
      }
    }

  }

  onFocus(event: any){
    if(!event.sourceCapabilities){
      this.input?.nativeElement.select()
    }
  }

  onInputKeyDown(event: KeyboardEvent){
    switch (event.which) {
      //up
      case 38:
        this.increase();
        event.preventDefault();
        break;
      //down
      case 40:
        this.decrease();
        event.preventDefault();
        break;
      //left
      case 37:
        break;
      //right
      case 39:
        break;
      //enter
      case 13:
        this.enterKeyPressed.emit({key: 'Enter'})
        break;
      //backspace
      case 8: {
        break;
      }
      // del
      case 46:
        break;
      default:
        break;
    }
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * handle key up on +/- buttons
   * @param event Keyboard event
   * @param buttonType Button type
   */
  handleKeyUp(event: KeyboardEvent, buttonType: 'add' | 'decrease'){
    if(event.key == 'Enter' && !this.disabled){
      if(buttonType == 'add'){
        this.increase();
      } else {
        this.decrease();
      }
    }
  }


}
