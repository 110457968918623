import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from "./auth/guards/auth.guard";
import {NotFoundComponent} from "./not-found/not-found.component";
import {OnboardingGuard} from "./guards/onboarding.guard";
import {DownloadFileComponent} from "./download-file/download-file.component";

const routes: Routes = [
  {
    path: 'referral',
    loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'shop-and-browse',
    redirectTo: 'brands',
    pathMatch: "prefix",
  },
  {
    path: 'brands',
    loadChildren: () => import('./shop-and-browse/shop-and-browse.module').then(m => m.ShopAndBrowseModule),
    canActivate: [AuthGuard, OnboardingGuard]
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m =>m.OnboardingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'management',
    loadChildren: () => import('./management/management.module').then(m =>m.ManagementModule),
    canActivate: [AuthGuard,OnboardingGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then(m =>m.CartModule),
    canActivate: [AuthGuard,OnboardingGuard]
  },
  {
    path: 'quotes',
    loadChildren: () => import('./quotes/quotes.module').then(m =>m.QuotesModule),
    canActivate: [AuthGuard,OnboardingGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m =>m.OrdersModule),
    canActivate: [AuthGuard,OnboardingGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then(m =>m.CheckoutModule),
    canActivate: [AuthGuard,OnboardingGuard]
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./wish-list/wish-list.module').then(m =>m.WishListModule),
    canActivate: [AuthGuard,OnboardingGuard]
  },
  {
    path: 'help-center',
    loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'shop-products',
    pathMatch: 'prefix',
    redirectTo: 'shop'
  },
  {
    path: 'showroom',
    loadChildren: () => import('./showroom-self-serve/showroom-self-serve.module').then(m => m.ShowroomSelfServeModule),
    canActivate: [AuthGuard, OnboardingGuard],
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
    canActivate: [AuthGuard, OnboardingGuard],
  },
  {
    path: 'download',
    component: DownloadFileComponent
  },
  {
    path: '',
    redirectTo: 'shop',
    pathMatch: "full"
  },

  // OLD B2B LINK REDIRECTIONS
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'account/saved-addresses',
    redirectTo: 'management/addresses',
    pathMatch: 'full',
  },
  {
    path: 'account/company-staff',
    redirectTo: '/management/users',
    pathMatch: 'full',
  },
  {
    path: 'account/property-staff',
    redirectTo: '/management/users',
    pathMatch: 'full',
  },
  {
    path: 'account/settings',
    redirectTo: '/management/account',
    pathMatch: 'full',
  },
  {
    path: 'payment/checkout/:id',
    redirectTo: '/checkout/:id'
  },
  {
    path: 'payment/ach-checkout/:id',
    redirectTo: '/checkout/:id'
  },
  {
    path: 'account',
    redirectTo: '/management/account',
    pathMatch: "prefix"
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
