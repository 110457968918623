import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, catchError, firstValueFrom, map, take} from "rxjs";
import {environment} from "../../../environments/environment";
import {Product} from "../../interfaces/spaceInformation.type";
import {AuthService} from "../auth/auth.service";

interface AssignProductToSpaces {
  productList: Partial<Product>[],
  spaceList: number[],
  propertyId: number,
}

interface RemoveProductFromSpaces {
	productList: {id: string}[],
	spaceId: number,
	propertyId: number,
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  // To store initial product data by resolver
  INIT_PRODUCT_LIST = new BehaviorSubject<any>(null)

  // Count of products marked as favorite for showroom being set up
  _FAVORITE_PRODUCT_COUNT: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  _FAVORITE_PRODUCT_IDS: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])

  _ASSIGNED_PRODUCT_COUNT : BehaviorSubject<number> = new BehaviorSubject<number>(0)

  _UNASSIGNED_PRODUCT_COUNT : BehaviorSubject<number> = new BehaviorSubject<number>(0)

  _INIT_UNASSIGNED_PRODUCTS : number = 0

  constructor(
    public http: HttpClient,
    public auth: AuthService,
  ) {

  }

  /**
   * [POST] API to get product list
   * @param body
   */
  getProductList(body: any) {
    return this.http.post(`${environment.apiUrl}/showroom/product/list`, body).pipe(
      take(1), map((response: any) => {
        this.FAVORITE_PRODUCT_COUNT = response.data.favoriteProductCount
        this.assignedProductsCount = response.data.assignedPropertyProductCount
        this.unAssignedProductsCount = response.data.unassignedPropertyProductCount
        return response.data
      }), catchError(err => {
		    throw err
	    })
    );
  }

  /**
   * [POST] API to update isArchived, isLocal, isFavorite booleans of products
   * @param body Request body
   */
  updateProductTags(body: any) {
    return this.http.put(`${environment.apiUrl}/update/product/tag`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [POST] API to assign a product to given spaces
   * @param body Request body
   */
  assignProductToSpaces(body: AssignProductToSpaces) {
    return this.http.post(`${environment.apiUrl}/space/product/assign`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [POST] API to remove item from a space
    * @param body Request body
   */
  removeProductFromSpace(body: RemoveProductFromSpaces) {
	  return this.http.post(`${environment.apiUrl}/space/product/remove`, body).pipe(
		  take(1), map((response: any) => response.data), catchError(err => {
			  throw err
		  })
	  );
  }

  /**
   * [POST] API to move item from one space to another
   * @param body Request body
   */
  moveItemsToSpace(body: any) {
    return this.http.post(`${environment.apiUrl}/space/product/move`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * To form product data for assignment
   * @param product Product Information
   */
  getProductDataForAssignment(product: Product) {
    return {
      id: product.id,
      productName: product.productName ? product.productName : undefined,
      brandId: product.brandId ? product.brandId : undefined,
      brandName: product.brandName ? product.brandName : undefined,
      image: product.image ? product.image : undefined,
      productUrl: product.productUrl ? product.productUrl : undefined,
      affiliateUrl: product.affiliateUrl ? product.affiliateUrl : undefined,
      showroomProductName: product.showroomProductName ? product.showroomProductName : undefined,
      source: product.source ? product.source: undefined,
      isFavourite: product.isFavourite ? product.isFavourite: undefined,
      isLocal: product.isLocal ? product.isLocal: undefined,
    }
  }

  /**
   * [POST] API to add new item
   * @param body Request body
   */
  addItemFromSpace(body: any) {
    return this.http.post(`${environment.apiUrl}/space/item/add`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [POST] API to update item info
   * @param body Request body
   */
  updateManualItem(body: any) {
    return this.http.post(`${environment.apiUrl}/space/item/edit`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [PUT] API to set only marked products as favorite and remove others
   * @param body Request body
   */
  setFavouriteProducts(body: any) {
    return this.http.put(`${environment.apiUrl}/update-favorite-product`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [POST] API to get list of favourite products
   * @param body Request body
   */
  getFavouriteProductsList(body: any) {
    return this.http.post(`${environment.apiUrl}/showroom/property/product/list`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [POST] API to remove manually created item
   * @param body Request body
   */
  removeManualItem(body: any) {
    return this.http.post(`${environment.apiUrl}/product/delete`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * [POST] API to update space list of a product
   * @param body Request body
   */
  updateProductSpaceList(body: any) {
    return this.http.post(`${environment.apiUrl}/single/product/assign`, body).pipe(
      take(1), map((response: any) => response.data), catchError(err => {
        throw err
      })
    );
  }

  /**
   * Switch to unassigned products if atleast one product is unassigned
   * @param propertyId Property ID
   * @param isEdit if showroom is in edit mode
   */
  async setProductList(propertyId: number, isEdit =  false) {
    // If product list is empty, load it
    const requestBody = {
      properties: [propertyId],
      order: [{
        columnName: 'purchasedAt',
        direction: false,
      }],
      search: "",
      filterType: "all",
      propertyId: propertyId,
    }
    const response = await firstValueFrom(this.getProductList(requestBody)).catch(err => {
      throw err
    })

    if(response.allCount === 0 && !isEdit){
      const user = this.auth.user.getValue();
      const properties = [...user?.permissions.find(each => each.companyId === user.account.activeCompanyId).propertyAccess];
      const newRequest = {
        ...requestBody,
        properties: properties.map(property => property.propertyId),
        filterType: "all",
      }
      const response = await firstValueFrom(this.getProductList(newRequest)).catch(err => {
        throw err
      })
      this.INIT_PRODUCT_LIST.next({...response, selectAllProperty: true})
      this._INIT_UNASSIGNED_PRODUCTS = this.INIT_PRODUCT_LIST.getValue().unassignedPropertyProductCount;
    }
    else if (response.unassignedProductCount > 0) {
      const newRequest = {
        ...requestBody,
        filterType: "unassigned",
      }
      const response = await firstValueFrom(this.getProductList(newRequest)).catch(err => {
        throw err
      })
      this.INIT_PRODUCT_LIST.next({...response, unassigned: true})
      this._INIT_UNASSIGNED_PRODUCTS = this.INIT_PRODUCT_LIST.getValue().unassignedPropertyProductCount;
    } else {
      this.INIT_PRODUCT_LIST.next(response)
      this._INIT_UNASSIGNED_PRODUCTS = this.INIT_PRODUCT_LIST.getValue().unassignedPropertyProductCount;
    }
  }

  get FAVORITE_PRODUCT_COUNT() {
	  return this._FAVORITE_PRODUCT_COUNT.getValue()
  }

  set FAVORITE_PRODUCT_COUNT(count: number) {
	  this._FAVORITE_PRODUCT_COUNT.next(count)
  }

  get FAVORITE_PRODUCT_IDS() {
	  return this._FAVORITE_PRODUCT_IDS.getValue()
  }

  set FAVORITE_PRODUCT_IDS(count: string[]) {
	  this._FAVORITE_PRODUCT_IDS.next(count)
  }
  get assignedProductsCount(){
    return this._ASSIGNED_PRODUCT_COUNT.getValue()
  }
  set assignedProductsCount(count){
    this._ASSIGNED_PRODUCT_COUNT.next(count)
  }

  get unAssignedProductsCount(){
    return this._UNASSIGNED_PRODUCT_COUNT.getValue()
  }
  set unAssignedProductsCount(count){
    this._UNASSIGNED_PRODUCT_COUNT.next(count)
  }


}
