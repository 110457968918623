import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EllipsisModule} from "ngx-ellipsis";
import {CommaListTooltipComponent} from "./comma-list-tooltip/comma-list-tooltip.component";
import {DateRangeDisplayComponent, noTzPipe} from "./date-range-display/date-range-display.component";
import {EmailDisplayComponent} from "./email-display/email-display.component";
import {NotesDisplayComponent} from "./notes-display/notes-display.component";
import {ProductNameComponent} from "./product-name/product-name.component";
import {CustomListboxComponent} from "./custom-listbox/custom-listbox.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "../directives/directives.module";
import {InputTextModule} from "primeng/inputtext";
import {TooltipModule} from "primeng/tooltip";
import {ItemMenuComponent} from "./item-menu/item-menu.component";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {PipesModule} from "../pipes/pipes.module";
import {ProductImageCarouselComponent} from './product-image-carousel/product-image-carousel.component';
import {DialogModule} from "primeng/dialog";
import {CheckboxModule} from "primeng/checkbox";
import {TimeRangeDisplayComponent} from './time-range-display/time-range-display.component';
import {SkeletonModule} from "primeng/skeleton";
import {CreditTooltipComponent} from './credit-tooltip/credit-tooltip.component';
import {CarouselModule} from 'primeng/carousel';
import {SlickCarouselModule} from "ngx-slick-carousel";


@NgModule({
  declarations: [
    CommaListTooltipComponent,
    DateRangeDisplayComponent,
    EmailDisplayComponent,
    NotesDisplayComponent,
    ProductNameComponent,
    noTzPipe,
    CustomListboxComponent,
    ItemMenuComponent,
    ProductImageCarouselComponent,
    TimeRangeDisplayComponent,
    CreditTooltipComponent,
  ],
  imports: [
    CommonModule,
    EllipsisModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    InputTextModule,
    TooltipModule,
    NgbDropdownModule,
    ScrollingModule,
    PipesModule,
    DialogModule,
    CheckboxModule,
    SkeletonModule,
    CarouselModule,
    SlickCarouselModule,
  ],
	exports: [
		CommaListTooltipComponent,
		DateRangeDisplayComponent,
		EmailDisplayComponent,
		NotesDisplayComponent,
		ProductNameComponent,
		noTzPipe,
		CustomListboxComponent,
		ItemMenuComponent,
		ProductImageCarouselComponent,
		TimeRangeDisplayComponent,
    CreditTooltipComponent,
	]
})
export class DisplayDataModule { }
