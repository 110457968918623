import {Component} from '@angular/core';
import {ToastService} from "../../../services/misc/toast.service";


@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  constructor(
    public toastService: ToastService
  ) { }

  deleteToast(toastMessage: any){
    setTimeout(() => {
      this.toastService.remove(toastMessage)
    }, 300)
  }
}
