import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent implements OnInit{
  constructor(
    private route: ActivatedRoute
  ) {
  }

  queryParams:any;
  isLoading: boolean = true;

  ngOnInit(): void {
    this.queryParams = this.route.snapshot.queryParams;
    if (this.queryParams?.fileUrl){
      this.downloadAndCloseTab(this.queryParams.fileUrl,this.queryParams.fileName).then(r => {
        // window.close();
       setTimeout(()=>{
         this.isLoading = false;
       },1000)
      })
    }
  }

  async downloadAndCloseTab(fileToDownload:string,fileName:string) {
    let link = document.createElement('a');
    link.href = await this.toDataURL(fileToDownload);
    link.download = `Minoan QR Code - ${fileName}`;
    document.body.appendChild(link);
    link.click();
  }

  async toDataURL(url: string) {
    const blob = await fetch(url).then(res => res.blob());
    return URL.createObjectURL(blob);
  }

  downloadFileNow() {
    let link = document.createElement('a');
    this.toDataURL(this.queryParams.fileUrl).then((value)=>{
      link.href = value;
      link.download = `Minoan QR Code - ${this.queryParams.fileName}`;
      document.body.appendChild(link);
      link.click();
    })
  }
}
