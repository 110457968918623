import {Injectable} from '@angular/core';
import {User} from "../auth/auth.service";
import {environment} from "../../../environments/environment";
import {AES, enc} from 'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {


  constructor() { }

  /**
   * Method to encrypt Data
   * @param data
   */
  encryptData(data:any) {
    // return JSON.stringify(data)
    try {
      return AES.encrypt(JSON.stringify(data), environment.encryptionKey).toString();
    } catch (e) {
      return '';
    }
  }

  /**
   * Method to decrypt Data
   * @param data
   */
  decryptData(data:any): User | null {
    // return JSON.parse(data)
    try {
      const bytes = AES.decrypt(data, environment.encryptionKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(enc.Utf8));
      }
      return data;
    } catch (e) {
      return null;
    }
  }


}
