import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

interface accountSetupDetails {
  firstName: string,
  lastName: string,
  contactNumber: string,
  email: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {

  email: BehaviorSubject<string> = new BehaviorSubject('')

  accountDetails: BehaviorSubject<accountSetupDetails> = new BehaviorSubject<accountSetupDetails>({
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: ''
  });

  constructor() { }

  set emailValue(data : string){
    this.email.next(data)
  }

  get emailValue(){
    return this.email.getValue();
  }

  set accountDetailValue(data : accountSetupDetails){
    this.accountDetails.next(data)
  }

  get accountDetailValue(){
    return this.accountDetails.getValue();
  }

}
