import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LoomComponent} from './loom/loom.component';
import {DialogModule} from "primeng/dialog";
import {BrandDetailsComponent} from './brand-details/brand-details.component';
import {PipesModule} from "../../pipes/pipes.module";
import {BrandTileDataComponent} from './brand-tile-data/brand-tile-data.component';


@NgModule({
  declarations: [
    LoomComponent,
    BrandDetailsComponent,
    BrandTileDataComponent,
  ],
  exports: [
    LoomComponent,
    BrandDetailsComponent,
    BrandTileDataComponent,
  ],
	imports: [
		CommonModule,
		DialogModule,
		NgOptimizedImage,
		PipesModule
	]
})
export class CommonModalsModule { }
