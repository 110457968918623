import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, map, take} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";

interface CheckoutHeaderInfo {
  navigatedFrom: 'quote' | 'order',
  selectedOrderId: string
}

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(
    public http: HttpClient
  ) { }

  /**
   * To inject GetBalance script into the page if it does not already exist
   */
  loadBalanceScript(){
    const doesScriptExist = document.getElementById('getBalanceScript');
    if(!doesScriptExist) {
      let body = <HTMLDivElement> document.body;
      let script = document.createElement('script');
      script.innerHTML = '';
      script.src = environment.GET_BALANCE_URL;
      script.async = true;
      script.defer = true;
      script.id = 'getBalanceScript';
      body.appendChild(script);
    }
  }

  /**
   * [POST] API to get payment token for GetBalance
   * @param body Request body
   */
  getPaymentTokenOfGetBalance(body:any){
    return this.http.post(`${environment.apiUrl}/order/v2/payment`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to set isDisabled key for quote/order after GetBalance widget emits OnSuccess
   * @param body Request body
   */
  updateOrderPaymentStatus(body:any){
    return this.http.post(`${environment.apiUrl}/balance/payment/b2b/success`, body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [GET] API to fetch the state of the order: quote/order
   * @param orderId Order ID
   */
  checkPaymentStatus(orderId:any){
    let params = new HttpParams();
    params = params.append('orderId', orderId);
    return this.http.get(`${environment.apiUrl}/fetch/order/state`,{ params })
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * [POST] API to place order when credits >= total cost of the order
   * @param body Request body
   */
  placeOrderNow(body:any){
    return this.http.post(`${environment.apiUrl}/order/payment/place`,body)
      .pipe(take(1), map((response : any) => response.data), catchError((err) => {throw err}))
  }

  /**
   * To store information about the order being checked out currently
   */
  headerInformation = new BehaviorSubject<CheckoutHeaderInfo>({
    navigatedFrom: 'quote',
    selectedOrderId: ''
  })

  /**
   * Getter for current header information
   */
  get checkoutHeaderInfo() {
    return this.headerInformation.getValue()
  }
}
