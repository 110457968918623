import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddItemModalComponent} from './add-item-modal/add-item-modal.component';
import {DialogModule} from "primeng/dialog";
import {DirectivesModule} from "../../directives/directives.module";
import {FormElementsModule} from "../../form-elements/form-elements.module";
import {NgxTrimDirectiveModule} from "ngx-trim-directive";
import {ReactiveFormsModule} from "@angular/forms";
import {EditItemModalComponent} from './edit-item-modal/edit-item-modal.component';
import {CsvUploadModalsComponent} from './csv-upload-modals/csv-upload-modals.component';
import {PipesModule} from "../../pipes/pipes.module";
import {ArchiveQuoteComponent} from './archive-quote/archive-quote.component';
import {CheckboxModule} from "primeng/checkbox";
import {DisplayDataModule} from "../../display-data/display-data.module";
import {NgbDropdown, NgbDropdownMenu, NgbDropdownToggle} from "@ng-bootstrap/ng-bootstrap";
import {PaginatorModule} from "primeng/paginator";


@NgModule({
  declarations: [
    AddItemModalComponent,
    EditItemModalComponent,
    CsvUploadModalsComponent,
    ArchiveQuoteComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    DirectivesModule,
    FormElementsModule,
    NgxTrimDirectiveModule,
    ReactiveFormsModule,
    PipesModule,
    CheckboxModule,
    DisplayDataModule,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    PaginatorModule,
  ],
    exports: [
        AddItemModalComponent,
        EditItemModalComponent,
        CsvUploadModalsComponent,
        ArchiveQuoteComponent,
    ]
})
export class ItemModalsModule { }
