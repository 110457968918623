import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, map, Observable, take} from "rxjs";
import {environment} from "../../../environments/environment";
import {CartWishlistItemList} from "../../interfaces/cart-wishlist-brandInfoForm.type";

export interface WishlistProductAdd {
	productName: string,
	quantity: number;
	url: string;
	variant: string;
	productImageUrl: string,
}

export interface WishlistProductEdit {
	productName: string,
	quantity: number;
	url: string;
	variant: string;
	companyId: number;
	id: number;
	productImageUrl?: string,
}

export interface WishlistSingleItemDelete {
	id: number,
}

export interface WishlistMultipleItemMoveToCart {
  wishlistIdList: number[],
}

export interface WishlistMultipleItemDelete {
	idList: number[]
}

export interface WishlistMoveMultipleItemToQuote {
	moveTo: 'quote',
	wishlistIdList: number[],
	quoteId: number
}

export interface WishlistDetailsResponse {
	count: number,
	totalQuantity: number,
	wishlistItemList: CartWishlistItemList[]
}


@Injectable({
	providedIn: 'root'
})
export class WishlistService {

	constructor(public http: HttpClient) {
	}

	/**
	 * [POST] API to add new item to cart
	 * @param body Request body
	 */
	submitAddProduct(body: WishlistProductAdd): Observable<any> {
		return this.http.post(`${environment.apiUrl}/wishlist/add`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to add new item to cart
	 * @param body Request body
	 */
	getWishListDetails(body: any): Observable<WishlistDetailsResponse> {
		return this.http.post(`${environment.apiUrl}/wishlist/list`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [PUT] API to edit item to cart
	 * @param body Request body
	 */
	submitEditProduct(body: WishlistProductEdit): Observable<any> {
		return this.http.put(`${environment.apiUrl}/wishlist/edit`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [DELETE] API to remove the item from cart
	 * @param body Request body
	 */
	submitDeleteItemWishlist(body: WishlistSingleItemDelete): Observable<any> {
		return this.http.delete(`${environment.apiUrl}/wishlist/delete?id=${body.id}`)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}


	/**
	 * [POST] API to remove the multiple item from cart
	 * @param body Request body
	 */
	deleteBulkProducts(body: WishlistMultipleItemDelete): Observable<any> {
		return this.http.post(`${environment.apiUrl}/wishlist/multiple/delete`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

  /**
   * [POST] API to move a single product from wishlist to cart
   * @param body
   */
	moveSingleProductCart(body: WishlistSingleItemDelete): Observable<any> {
		return this.http.post(`${environment.apiUrl}/wishlist/cart`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to move products out of a quote to some other quote, cart, wishlist
	 * @param body
	 */
	moveBulkProductsToCart(body: WishlistMultipleItemMoveToCart): Observable<any> {
		return this.http.post(`${environment.apiUrl}/wishlist/multiselect/move`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

	/**
	 * [POST] API to move multiple products from cart/wishlist to a quote
	 * @param body Request body
	 */
	moveProductsFromWishlistToQuote(body: WishlistMoveMultipleItemToQuote): Observable<any> {
		return this.http.post(`${environment.apiUrl}/move/wishlist/item`, body)
			.pipe(take(1), map((response: any) => response.data), catchError((err) => {
				throw err
			}))
	}

}
