import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {BehaviorSubject, catchError, map, take} from "rxjs";
import {HttpClient} from "@angular/common/http";

export interface Flags {
  holidaySaleEnabled: boolean
}

@Injectable({
  providedIn: 'root'
})
export class FlagsmithService {
  flagsmithFlag: BehaviorSubject<Flags> = new BehaviorSubject<Flags>({
    holidaySaleEnabled: false
  })

  constructor(
    public http: HttpClient,
  ) {
  }

  /**
   * Set flags in flagsmith behaviour subject
   * @param data Flag data
   */
  set setFlagsmithFlags(data: any) {
    this.flagsmithFlag.next(data)
  }

  /**
   * Get all flags and assign them to Behaviour Subject
   */
  initFlagsmith() {
    this.getFlagsmithFlags().subscribe({
      next: (response: any) => {
        const flags = {
          holidaySaleEnabled: response.holidaySaleEnabled
        }
        this.setFlagsmithFlags = flags
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  /**
   * [GET] API to fetch all flags from API
   */
  getFlagsmithFlags() {
    return this.http.get(`${environment.apiUrl}/b2b/get/holiday/sale/enabled`).pipe(
      take(1),
      map((response: any) => response.data),
      catchError((err) => {throw err})
    )
  }

}
