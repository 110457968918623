import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

@Directive({
  selector: '[appDoubleClick]'
})
export class DoubleClickDirective implements OnInit, OnDestroy {

  @Input() throttleTime = 2000;
  @Input() isDisable: boolean = false;

  @Output()
  throttledClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  /**
   *
   */
  ngOnInit() {
    this.subscription?.unsubscribe();
    this.subscription = this.clicks.pipe(throttleTime(this.throttleTime)).subscribe({
      next: (e) => {
        this.emitThrottledClick(e)
        if(this.isDisable) {
          setTimeout(() => {
            this.renderer.removeClass(this.el.nativeElement, 'throttle_disabled');
          }, this.throttleTime)
        }
      }
    });
  }

  /**
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if(changes['throttleTime']) {
      const change = changes['throttleTime']
      if(change.currentValue !== change.previousValue) {
	      this.subscription?.unsubscribe();
	      this.subscription = this.clicks.pipe(throttleTime(this.throttleTime)).subscribe({
		      next: (e) => {
			      this.emitThrottledClick(e)
		      }
	      });
      }
    }
  }

  emitThrottledClick(e:any) {
    this.throttledClick.emit(e);
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
    if (this.subscription === undefined) {
    } else {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    if(this.isDisable) {
      this.renderer.addClass(this.el.nativeElement, 'throttle_disabled');
    }
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
