import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-email-display',
  templateUrl: './email-display.component.html',
  styleUrls: ['./email-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailDisplayComponent implements OnInit {

  constructor() { }

  emailBehaviourSubject = new BehaviorSubject<string>('')

  @Input() set email(value: string){
    this.emailBehaviourSubject.next(value);
  }

  displayString: { userName:string, website: string } = {userName: '', website: ''}

  ngOnInit(): void {
    this.emailBehaviourSubject.subscribe({
      next: value => {
        // if(value){
        //   this.displayString = {
        //     userName: value.split('@')[0],
        //     website: value.split('@')[1]
        //   }
        // }
        if (value) {
          const parts = value.split('@');
          if (parts.length >= 2) {
            const website = parts.pop()!; // Remove the last element (website) from the array
            const userName = parts.join('@'); // Join the remaining parts to get the username

            this.displayString = {
              userName: userName,
              website: website
            };
          }
        }
      }
    })

  }

}
