<div class="">
  <div class="product_slide" >
    <h4>Selected Image</h4>
    <div class="main_image" [ngClass]="{'more_than_one_img': images.length > 1 && !imagesWrongChecked, 'image_skeleton': showMainImageSkeleton, 'no-image': isNoImage, 'no_img_outer': images[0] === PLACEHOLDER_URL && type === 'self_serve'  }">

      <ng-container *ngIf="showMainImageSkeleton; else showMainImage">
        <p-skeleton class="custom_skeleton" height="138px"></p-skeleton>
      </ng-container>
      <ng-template #showMainImage>
        <div class="upload_img_outer" *ngIf="images[0] === PLACEHOLDER_URL && type === 'self_serve'; else mainImage">
          <div  class="upload_img_container">
<!--            <input class="file_upload" type="file" accept="image/*" (change)="fireEventOnImageUpload($event)">-->
<!--            <div class="btn_upload_wrapper">-->
<!--             <div class="btn_upload_inner">-->
<!--                <span class="upload_icons">-->
<!--                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M9.75 15.75H2.7C2.45147 15.75 2.25 15.5485 2.25 15.3V2.7C2.25 2.45147 2.45147 2.25 2.7 2.25H15.3C15.5485 2.25 15.75 2.45147 15.75 2.7V9.75" stroke="#DC5A4B" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M2.25 12L7.5 9.75L11.625 11.625" stroke="#DC5A4B" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M12 7.5C11.1716 7.5 10.5 6.82843 10.5 6C10.5 5.17157 11.1716 4.5 12 4.5C12.8284 4.5 13.5 5.17157 13.5 6C13.5 6.82843 12.8284 7.5 12 7.5Z" stroke="#DC5A4B" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M12 14.25H14.25M16.5 14.25H14.25M14.25 14.25V12M14.25 14.25V16.5" stroke="#DC5A4B" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
<!--              </span>-->
<!--               <p class="upload-text">Upload image</p>-->
<!--             </div>-->
<!--            </div>-->
              <div class="no_img_container">
                <span class="inner_icons">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35 20V8.33333C35 6.49238 33.5076 5 31.6667 5H8.33333C6.49238 5 5 6.49238 5 8.33333V26.6667M35 20V25V31.6667C35 33.5076 33.5076 35 31.6667 35H26.6667M35 20C24.2628 20 18.1577 23.3086 14.9084 27.0711M5 26.6667V31.6667C5 33.5076 6.49238 35 8.33333 35V35H11.6667H26.6667M5 26.6667C7.33821 26.277 11.0614 26.1788 14.9084 27.0711M26.6667 35C23.8274 30.3862 19.2877 28.0869 14.9084 27.0711M14.1667 11.6667C13.3333 11.6667 11.6667 12.1667 11.6667 14.1667C11.6667 16.1667 13.3333 16.6667 14.1667 16.6667C15 16.6667 16.6667 16.1667 16.6667 14.1667C16.6667 12.1667 15 11.6667 14.1667 11.6667Z" stroke="#C7C7C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </div>
            <div class="fg-inner error_wrapper" *ngIf="isNoImage">
              <small class="error-1 upload_img_error ">{{errorService.ADD_PRODUCT_IMAGE_REQUIRED}}</small>
            </div>
          </div>
        </div>
        <ng-template #mainImage>
          <img id="mainImage" src="{{imagesWrongChecked ? PLACEHOLDER_URL : (images.length ? images[mainImageIndex] : PLACEHOLDER_URL)}}" alt="img" />
        </ng-template>
      </ng-template>


      <span *ngIf="!imagesWrongChecked && images.length > 1" class="check_icon">
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#12B76A"/>
        <path d="M16.4286 9.64282L9.75007 16.3214L6.71436 13.2857" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </span>

      <div class="slide_btn">
        <button class="btn prev_btn" id="prevProductImageBtn" type="button" *ngIf="images.length > 1 && !imagesWrongChecked" (click)="prevImage()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
            <path d="M12.5 15L7.5 10L12.5 5" stroke="#DC5A4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <button class="btn next_btn" id="nextProductImageBtn" type="button" *ngIf="images.length > 1 && !imagesWrongChecked" (click)="nextImage()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
            <path d="M7.5 15L12.5 10L7.5 5" stroke="#DC5A4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="thumbnail_list" *ngIf="images.length > 1 && !showSkeleton" >
      <ng-container *ngFor="let image of images; let i = index">
        <div class="thumbnail_card desk_hover_only" [ngClass]="{'images_disabled': imagesWrongChecked}" id="productImage-{{i}}" (click)="imageClicked(i)">
          <img src="{{image}}" alt="img"/>
        </div>
      </ng-container>
    </div>

    <div class="thumbnail_list" *ngIf="showSkeleton && type === 'quote'">
      <ng-container *ngFor="let _ of [].constructor(6)">
        <div class="thumbnail_card desk_hover_only images_disabled">
          <p-skeleton height="40px"></p-skeleton>
        </div>
      </ng-container>
    </div>

    <div class="form-group"  *ngIf="showNoneOfTheseCheckbox">
      <div class="fg-inner">
        <div class="custom-checkbox-group">
          <p-checkbox [binary]="true" inputId="noImages-desktop" styleClass="custom-checkbox-button" [(ngModel)]="imagesWrongChecked" (onChange)="imagesWrongCheckedEventHandler($event)"></p-checkbox>
          <label class="checkbox-button-label" for="noImages-desktop">None of these show my item</label>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile_carousel_selector" [ngClass]="{'no_images_mob': !showNoneOfTheseCheckbox}">
    <button class="btn btn_outline" (click)="mobileCarousel = true">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M12.15 15.75H5.19853C4.74417 15.75 4.51699 15.75 4.41179 15.6602C4.32051 15.5822 4.27207 15.4652 4.28149 15.3456C4.29234 15.2077 4.45298 15.047 4.77426 14.7257L11.1515 8.34853C11.4485 8.05152 11.597 7.90301 11.7682 7.84737C11.9189 7.79842 12.0811 7.79842 12.2318 7.84737C12.403 7.90301 12.5515 8.05152 12.8485 8.34853L15.75 11.25V12.15M12.15 15.75C13.4101 15.75 14.0402 15.75 14.5215 15.5048C14.9448 15.289 15.289 14.9448 15.5048 14.5215C15.75 14.0402 15.75 13.4101 15.75 12.15M12.15 15.75H5.85C4.58988 15.75 3.95982 15.75 3.47852 15.5048C3.05516 15.289 2.71095 14.9448 2.49524 14.5215C2.25 14.0402 2.25 13.4101 2.25 12.15V5.85C2.25 4.58988 2.25 3.95982 2.49524 3.47852C2.71095 3.05516 3.05516 2.71095 3.47852 2.49524C3.95982 2.25 4.58988 2.25 5.85 2.25H12.15C13.4101 2.25 14.0402 2.25 14.5215 2.49524C14.9448 2.71095 15.289 3.05516 15.5048 3.47852C15.75 3.95982 15.75 4.58988 15.75 5.85V12.15M7.875 6.375C7.875 7.20343 7.20343 7.875 6.375 7.875C5.54657 7.875 4.875 7.20343 4.875 6.375C4.875 5.54657 5.54657 4.875 6.375 4.875C7.20343 4.875 7.875 5.54657 7.875 6.375Z" stroke="#444444" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
      Edit Image</button>
    <div class="mobile_image">
      <img src="{{imagesWrongChecked ? PLACEHOLDER_URL : images[mainImageIndex]}}">
    </div>
  </div>
</div>


<p-dialog [visible]="mobileCarousel" [showHeader]="false" [modal]="true" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false" styleClass="cart_modal "
          maskStyleClass="mask-class-2 padding0">
  <div class="modal-content">
    <div class="modal-header">
      <div class="heading_wrap">
        <h2 class="heading3">Select Image</h2>
      </div>
      <button (click)="mobileCarousel = false" type="button" class="close-btn">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5 1.5L1.5 16.5M1.5 1.5L16.5 16.5" stroke="#96999F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <div class="modal_body">
      <div class="mobile_wrap">
        <div class="mobile_thumbnail_wrap">
          <ng-container *ngFor="let image of images; let i = index">
            <div class="thumbnail_card" [ngClass]="{'image_checked': mainImageIndex == i && !imagesWrongChecked}" id="productImage-{{i}}" (click)="imageClicked(i)">
              <img src="{{image}}" alt="img"/>
              <span class="check_icon" *ngIf="mainImageIndex == i && !imagesWrongChecked">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#12B76A"/>
            <path d="M16.4286 9.64282L9.75007 16.3214L6.71436 13.2857" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer align-self-center p_sticky_mob">
      <div class="form-group center-ui">
        <div class="fg-inner">
          <div class="custom-checkbox-group">
            <p-checkbox [binary]="true" inputId="noImages-mobile" styleClass="custom-checkbox-button" [(ngModel)]="imagesWrongChecked" (onChange)="imagesWrongCheckedEventHandler($event)"></p-checkbox>
            <label class="checkbox-button-label" for="noImages-mobile">None of these show my item</label>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn_primary" id="imageSelectDone" (click)="mobileCarousel = false">
        Done
      </button>
    </div>
  </div>
</p-dialog>
