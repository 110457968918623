import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'replaceHttps'
})
export class ReplaceHttpsPipe implements PipeTransform {

  transform(value: string, iswww: boolean = true): string {
    if(!value){
      return value;
    }
    if(!iswww) {
      return value.replace(/^https:\/\//, '').replace('www.', '')
    }
    return value.replace(/^https:\/\//, 'www.');
  }

}
